import axios from "axios";
import { getCurrentUserToken } from "utils";

class UnitsProvider {
  axiosClient = axios.create({
    baseURL: `${process.env.REACT_APP_BASE_API_URL}/units`,
  });

  getUnits(page = null, query = null) {
    const token = getCurrentUserToken();
    return this.axiosClient.get("/", {
      params: { q: JSON.stringify(query), page: page },
      headers: { "X-USER-TOKEN": token },
    });
  }

  getUnit(id) {
    const token = getCurrentUserToken();
    return this.axiosClient.get(`/${id}`, {
      headers: { "X-USER-TOKEN": token },
    });
  }

  createUnit(unitParams) {
    const token = getCurrentUserToken();
    return this.axiosClient.post("/", unitParams, {
      headers: { "X-USER-TOKEN": token },
    });
  }

  updateUnit(id, unitParams) {
    const token = getCurrentUserToken();
    return this.axiosClient.put(`/${id}`, unitParams, {
      headers: { "X-USER-TOKEN": token },
    });
  }

  deleteUnit(unitId) {
    const token = getCurrentUserToken();
    return this.axiosClient.delete(`/${unitId}`, {
      headers: { "X-USER-TOKEN": token },
    });
  }

  getFloors() {
    const token = getCurrentUserToken();
    return this.axiosClient.get("/floors", {
      headers: { "X-USER-TOKEN": token },
    });
  }

  exportUnits(query = null) {
    const token = getCurrentUserToken();
    return this.axiosClient.get("/export", {
      params: { q: JSON.stringify(query) },
      responseType: "blob",
      headers: { "X-USER-TOKEN": token, "Content-Type": "application/json" },
    });
  }
}

const unitProviderInstance = new UnitsProvider();
export default unitProviderInstance;
