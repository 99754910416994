// React imports
import { useContext, useEffect, useRef, useState } from "react";

// External libraries
import { useNavigate, useParams } from "react-router-dom";

// Layouts
import MainLayout from "layouts/mainLayout";

// Material UI components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKAlert from "components/MKAlert";

// Providers
import invoicesProvider from "providers/invoicesProvider";
import { numericDateFormat } from "utils";
import { currencyFormatter } from "utils";
import { InputAdornment } from "@mui/material";
import SessionContext from "contexts/SessionContext";

//Custom components
import VideoLoader from "components/Animation/VideoLoader";

function EditInvoicePage() {
  const { currentUser } = useContext(SessionContext);
  // Get parameters from the URL
  const { id } = useParams();

  // React Router hook for navigation
  const navigate = useNavigate();

  // Loading state for the form
  const [loading, setLoading] = useState(true);

  // State for managing form errors
  const [formErrors, setFormErrors] = useState("");

  // State for invoice data
  const [invoice, setInvoice] = useState({
    start_date: "",
    end_date: "",
    payment_deadline: "",
    subtotal: "",
    total: "",
    surcharge: "",
    notes: "",
  });

  // state for selected end date in month input
  const [selectedEndDate, setSelectedEndDate] = useState("");
  const endDateMonthRef = useRef(null);

  // state for invoice rental
  const [invoiceMonthlyRent, setInvoiceMonthlyRent] = useState(null);

  useEffect(() => {
    const fetchInvoiceData = async () => {
      setLoading(true);
      try {
        const { data } = await invoicesProvider.getInvoice(id);
        setInvoice({
          start_date: data.start_date,
          end_date: data.end_date,
          payment_deadline: data.payment_deadline,
          subtotal: data.subtotal,
          total: data.total,
          surcharge: data.surcharge || "",
          notes: data.notes || "",
        });
        setSelectedEndDate(data.end_date.split("T")[0].slice(0, 7));
        setInvoiceMonthlyRent(data.rental.monthly_rent);
      } catch (error) {
        console.error("Error fetching invoice data", error);
        setFormErrors("Error al cargar los datos del cobro.");
      } finally {
        setLoading(false);
      }
    };

    fetchInvoiceData();
  }, [id]);

  // Handles changes in the form input fields and updates invoice state
  const handleInputChange = ({ target: { name, value } }) => {
    let total = invoice.total;
    let subtotal = Number(invoice.subtotal);
    let surcharge = Number(invoice.surcharge);

    if (name === "subtotal") {
      subtotal = Math.max(0, Number(value) || 0);
    } else if (name === "surcharge") {
      surcharge = Math.max(0, Number(value) || 0);
    }

    total = subtotal + surcharge;

    setInvoice((prevInvoice) => ({
      ...prevInvoice,
      [name]:
        name === "subtotal"
          ? subtotal
          : name === "surcharge"
          ? surcharge
          : value,
      total,
    }));
  };

  const handleStartDateChange = ({ target: { value } }) => {
    const newStartDate = new Date(value);
    const endDay = newStartDate.getUTCDate() - 1;
    const endDate = new Date(invoice.end_date);

    // Calculate the last day of the end date's month
    const lastDayOfMonth = new Date(
      endDate.getUTCFullYear(),
      endDate.getUTCMonth() + 1, // Next month
      0 // Day 0 of the next month gives the last day of the current month
    ).getUTCDate();

    // If endDay is less than 1, or if subtracting 1 from start day goes out of bounds, use the last day of the month
    const newEndDay = endDay < 1 ? lastDayOfMonth : endDay;

    const newEndDate = new Date(
      endDate.getUTCFullYear(),
      endDate.getUTCMonth(),
      newEndDay
    );

    setSelectedEndDate(newEndDate.toISOString().split("T")[0].slice(0, 7));
    setInvoice((prevInvoice) => ({
      ...prevInvoice,
      start_date: newStartDate.toISOString().split("T")[0],
      end_date: newEndDate.toISOString().split("T")[0],
    }));
  };

  const handleEndDateClick = (ev) => {
    ev.preventDefault();
    endDateMonthRef.current.children[0].children[0].showPicker();
  };

  const handleEndDateChange = ({ target: { value } }) => {
    const selectedEndDate = new Date(value);
    const endDay = new Date(invoice.start_date).getUTCDate() - 1;

    // Calculate the last day of the selected month
    const lastDayOfMonth = new Date(
      selectedEndDate.getUTCFullYear(),
      selectedEndDate.getUTCMonth() + 1, // Next month
      0 // Day 0 of the next month gives the last day of the current month
    ).getUTCDate();

    // If endDay is less than 1, or if subtracting 1 from start day goes out of bounds, use the last day of the month
    const adjustedDay = endDay < 1 ? lastDayOfMonth : endDay;

    // Fix the day to match the start date's day
    const newEndDate = new Date(
      selectedEndDate.getUTCFullYear(),
      selectedEndDate.getUTCMonth(),
      adjustedDay
    );

    setSelectedEndDate(newEndDate.toISOString().split("T")[0].slice(0, 7));
    setInvoice((prevInvoice) => ({
      ...prevInvoice,
      end_date: newEndDate.toISOString().split("T")[0],
    }));
  };

  useEffect(() => {
    // update payment deadline if end date is changed
    if (!invoice.end_date) return;

    const newPaymentDeadline = new Date(invoice.end_date);
    newPaymentDeadline.setUTCDate(
      newPaymentDeadline.getUTCDate() +
        Number(currentUser.subsidiary.payment_deadline_days || 5)
    );
    setInvoice((prevInvoice) => ({
      ...prevInvoice,
      payment_deadline: newPaymentDeadline.toISOString().split("T")[0],
    }));

    if (!invoiceMonthlyRent || !invoice.start_date) return;

    // multiply the monthly rent by the number of months in the billing period
    const invoiceEndDate = new Date(invoice.end_date);
    const invoiceStartDate = new Date(invoice.start_date);
    let months = invoiceEndDate.getUTCMonth() - invoiceStartDate.getUTCMonth();
    // Sum 1 month if the end date day is greater than the start date day
    if (invoiceEndDate.getUTCDate() > invoiceStartDate.getUTCDate()) {
      months += 1;
    }

    const subtotal = Number(invoiceMonthlyRent) * months;

    setInvoice((prevInvoice) => ({
      ...prevInvoice,
      subtotal,
      total: subtotal + Number(invoice.surcharge),
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoice.start_date, invoice.end_date, invoiceMonthlyRent]);

  // Submits the form after validating the data and handling errors
  const submitForm = async (event) => {
    event.preventDefault();

    try {
      await invoicesProvider.updateInvoice(id, invoice);
      navigate("/invoices", {
        state: { feedback: "Cobro actualizado correctamente" },
      });
    } catch (error) {
      let errorMsg = "Error en el servidor, favor de reportar el error";
      if (error.response && error.response.data) {
        errorMsg = error.response.data.join(", ");
      }
      setFormErrors(errorMsg);
    }
  };

  return (
    <MainLayout title="Editar Cobro">
      {loading ? (
        <MKBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="50vh"
        >
          <VideoLoader />
        </MKBox>
      ) : (
        <MKBox component="section" py={12}>
          <Container>
            <Grid container item xs={12} lg={7} sx={{ mx: "auto" }}>
              <MKBox width="100%" component="form" onSubmit={submitForm}>
                {formErrors && (
                  <MKAlert color="error" dismissible={true}>
                    {formErrors}
                  </MKAlert>
                )}
                {invoiceMonthlyRent && (
                  <MKBox mb={3}>
                    <MKAlert color="info">
                      <MKBox color="white" fontSize="1rem">
                        Precio mensual del contrato:
                        {currencyFormatter.format(invoiceMonthlyRent)}
                      </MKBox>
                    </MKAlert>
                  </MKBox>
                )}
                <MKBox p={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        name="start_date"
                        label="Fecha de inicio de periodo"
                        value={invoice.start_date}
                        variant="standard"
                        type="date"
                        fullWidth
                        onChange={handleStartDateChange}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        name="end_date_month"
                        value={selectedEndDate}
                        variant="standard"
                        type="month"
                        fullWidth
                        onChange={handleEndDateChange}
                        sx={{
                          visibility: "hidden", // Make the input invisible but still present in the DOM
                          width: "1px", // Minimize the width
                          height: "1px", // Minimize the height
                          position: "absolute", // Ensure it's not in the layout flow
                        }}
                        ref={endDateMonthRef}
                      />
                      <MKInput
                        name="end_date"
                        label="Fecha de fin de periodo"
                        value={numericDateFormat(invoice.end_date)}
                        variant="standard"
                        type="text"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        onClick={handleEndDateClick}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        name="payment_deadline"
                        label="Fecha límite de pago"
                        value={invoice.payment_deadline}
                        variant="standard"
                        type="date"
                        fullWidth
                        onChange={handleInputChange}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        name="subtotal"
                        label="Subtotal"
                        value={invoice.subtotal}
                        onChange={handleInputChange}
                        variant="standard"
                        type="number"
                        fullWidth
                        InputProps={{
                          startAdornment: (
                            <InputAdornment
                              position="start"
                              disableTypography={true}
                              sx={{ fontSize: "0.8rem" }}
                            >
                              $
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        name="surcharge"
                        label="Recargo"
                        value={invoice.surcharge}
                        onChange={handleInputChange}
                        variant="standard"
                        type="number"
                        fullWidth
                        InputProps={{
                          startAdornment: (
                            <InputAdornment
                              position="start"
                              disableTypography={true}
                              sx={{ fontSize: "0.8rem" }}
                            >
                              $
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        name="total"
                        label="Total"
                        value={currencyFormatter.format(invoice.total)}
                        variant="standard"
                        type="text"
                        disabled
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        name="notes"
                        label="Notas"
                        value={invoice.notes}
                        onChange={handleInputChange}
                        variant="standard"
                        type="text"
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                  <Grid container item justifyContent="center" xs={12} my={2}>
                    <MKButton
                      variant="contained"
                      color="primary"
                      type="submit"
                      fullWidth
                    >
                      Guardar Cambios
                    </MKButton>
                  </Grid>
                </MKBox>
              </MKBox>
            </Grid>
          </Container>
        </MKBox>
      )}
    </MainLayout>
  );
}

export default EditInvoicePage;
