import { useCallback, useState } from "react";
import { Link } from "react-router-dom";

import MKBox from "components/MKBox";
import Toolbar from "components/Toolbar";
import MKButton from "components/MKButton";

import rentalsProvider from "providers/rentalsProvider";
import RentalsTable from "pages/Rentals/table";

function CustomerRentalsTab({ customer }) {
  // Loading state for the table
  const [loading, setLoading] = useState(true);

  // Query state for searching rentals
  const [query, setQuery] = useState("");

  const fetchData = useCallback(
    async function (page) {
      setLoading(true);
      const {
        data: { data: rentalsData, total },
      } = await rentalsProvider.getRentals(page + 1, {
        user_id_eq: customer.id,
        ...query,
      });
      setLoading(false);
      return { rentals: rentalsData, total };
    },
    [query, customer]
  );
  return (
    <MKBox>
      <Toolbar
        setQuery={setQuery}
        ransackQuery="unit_name_or_status_cont"
        newItemButton={
          <MKBox mb={3}>
            {" "}
            <MKButton
              color="primary"
              size="small"
              variant="contained"
              component={Link}
              to="/rentals/new"
            >
              + Agregar Contrato
            </MKButton>
          </MKBox>
        }
      />
      <RentalsTable fetchData={fetchData} loading={loading} />
    </MKBox>
  );
}

export default CustomerRentalsTab;
