// React imports
import { useCallback, useEffect, useMemo, useState } from "react";

// React Router imports
import { Link, useLocation, useNavigate } from "react-router-dom";

// Layouts
import MainLayout from "layouts/mainLayout";

// Material UI components
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

// Material Kit 2 PRO React components
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Custom components
import Toolbar from "components/Toolbar";
import ActionsMenu from "components/Menu";
import VideoLoader from "components/Animation/VideoLoader";
import Table from "components/Table";
import customAlert from "components/Alerts/CustomAlert";

// Contexts
import { currencyFormatter } from "utils";

import productsProvider from "providers/productsProvider";

function ProductsPage() {
  // Converts URL search parameters to an object with "_cont" suffix.
  const location = useLocation();
  const searchParams = useMemo(() => {
    const params = new URLSearchParams(location.search);
    return Array.from(params.entries()).reduce((acc, [key, value]) => {
      acc[`${key}_cont`] = value;
      return acc;
    }, {});
  }, [location.search]);

  // Grouped state for managing products and table data
  const [state, setState] = useState({
    products: [], // List of products
    rows: [], // Processed rows for the table
    total: 0, // Total number of products (for pagination)
    page: 0, // Current page for pagination
    query: searchParams, // Query for searching products
  });

  // Loading state for the table
  const [loading, setLoading] = useState(true);

  // React Router hook for navigation
  const navigate = useNavigate();

  // Memoized columns for the table structure
  const columns = useMemo(
    () => [
      { name: "nombre", align: "left" },
      { name: "descripcion", align: "left" },
      { name: "precio", align: "center" },
      { name: "cantidad", align: "center" },
      { name: "opciones", align: "center" },
    ],
    []
  );

  // Handles page change event, updating the current page in the state
  const handlePageChange = useCallback((_ev, newPage) => {
    setState((prevState) => ({ ...prevState, page: newPage }));
  }, []);

  // Fetches the products data from the API, updating the "products" state whenever the query or page changes.
  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const {
        data: { data: productsData, total },
      } = await productsProvider.getProducts(state.page + 1, state.query);
      setState((prevState) => ({
        ...prevState,
        products: productsData || [],
        total: total || 0,
      }));
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        products: [],
        total: 0,
      }));
    } finally {
      setLoading(false);
    }
  }, [state.page, state.query]);

  //Triggers fetchData when page or query changes
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // Deletes a unit and refreshes the unit list
  const handleDeleteProduct = useCallback(
    (productID) => {
      customAlert({
        title: "¿Estás seguro?",
        text: "Esta acción no se puede deshacer. Se perderá la información del producto.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Sí, eliminar",
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await productsProvider.deleteProduct(productID);
            fetchData();
            customAlert({
              title: "Eliminado!",
              text: "El producto ha sido eliminado.",
              icon: "success",
              confirmButtonColor: "#3085d6",
            });
            navigate("/products");
          } catch (error) {
            customAlert({
              title: "Error",
              text: "Ocurrió un error al intentar eliminar el producto.",
              icon: "error",
              confirmButtonColor: "#d33",
            });
          }
        }
      });
    },
    [fetchData, navigate]
  );

  // Maps the unit data into table rows, updating the "rows" state whenever the units or suspendedUnits state changes.
  // This ensures that the table is always updated with the correct information, including the suspension status.
  useEffect(() => {
    if (state.products && state.products.length > 0) {
      setState((prevState) => ({
        ...prevState,
        rows: state.products.map((product) => ({
          nombre: (
            <MKTypography variant="button" fontWeight="medium">
              {product.name || ""}
            </MKTypography>
          ),
          descripcion: (
            <MKTypography variant="button" fontWeight="medium">
              {product.description || ""}
            </MKTypography>
          ),
          precio: (
            <MKTypography variant="button" fontWeight="medium">
              {currencyFormatter.format(product.price)}
            </MKTypography>
          ),
          cantidad: (
            <MKTypography variant="button" fontWeight="medium">
              {product.stock || ""}
            </MKTypography>
          ),
          opciones: (
            <Box display="flex" gap={1}>
              <ActionsMenu
                actions={[
                  {
                    icon: <EditIcon />,
                    text: "Editar",
                    onClick: () => navigate(`/products/${product.id}/edit`),
                  },
                  {
                    icon: <DeleteIcon />,
                    text: "Eliminar",
                    onClick: () => handleDeleteProduct(product.id),
                  },
                ]}
              />
            </Box>
          ),
        })),
      }));
    } else {
      setState((prevState) => ({ ...prevState, rows: [] }));
    }
  }, [state.products, navigate, fetchData, handleDeleteProduct]);

  return (
    <MainLayout title="Productos">
      <Toolbar
        setQuery={(query) =>
          setState((prevState) => ({
            ...prevState,
            query,
          }))
        }
        ransackQuery="name_or_price_or_description_cont"
        newItemButton={
          <Box display="flex" gap={2}>
            <MKButton
              variant="contained"
              size="small"
              color="primary"
              component={Link}
              to="/products/new"
            >
              + Agregar producto
            </MKButton>
          </Box>
        }
      />
      <Grid container item xs={12} lg={12}>
        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="15vh"
          >
            <VideoLoader />
          </Box>
        ) : state.products.length === 0 ? (
          <MKTypography variant="h6" fontWeight="medium">
            No se encontraron productos
          </MKTypography>
        ) : (
          <Table
            columns={columns}
            rows={state.rows}
            total={state.total}
            page={state.page}
            handlePageChange={handlePageChange}
          />
        )}
      </Grid>
    </MainLayout>
  );
}

export default ProductsPage;
