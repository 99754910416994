import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import MainLayout from "layouts/mainLayout";

// @mui material components
import Autocomplete from "@mui/material/Autocomplete";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKInput from "components/MKInput";
import MKAlert from "components/MKAlert";

// Providers
import customersProvider from "providers/customersProvider";
import invoicesProvider from "providers/invoicesProvider";
import paymentsProvider from "providers/paymentsProvider";
import { currencyFormatter } from "utils";

//Custom components
import VideoLoader from "components/Animation/VideoLoader";

function InvoicePaymentPage() {
  //Get the customer id from the URL
  const { id } = useParams();

  // Loading state for the form
  const [loadingPayment, setloadingPayment] = useState(true);
  const [loadingCustomer, setloadingCustomer] = useState(true);
  const [loadingCfdi, setloadingCfdi] = useState(true);
  const [loadingCfdiPayment, setloadingCfdiPayment] = useState(true);

  //State variables
  const [customerId, setCustomerId] = useState(null);
  const [formErrors, setFormErrors] = useState("");
  const [isModified, setIsModified] = useState(false);
  const [isInvoiceGenerated, setIsInvoiceGenerated] = useState(false);

  //Setting the state variables
  const [selectedCfdiUse, setSelectedCfdiUse] = useState(null);
  const [selectedFiscalRegimen, setSelectedFiscalRegimen] = useState(null);
  const [selectedCfdiType, setSelectedCfdiType] = useState(null);
  const [selectedPaymentForm, setSelectedPaymentForm] = useState(null);
  const [selectedPaymentMethods, setSelectedPaymentMethods] = useState(null);

  //Options for the Autocomplete components
  const [cfdiUses, setCfdiUses] = useState([]);
  const [fiscalRegimens, setFiscalRegimens] = useState([]);
  const [cfdiTypes, setCfdiTypes] = useState([]);
  const [paymentForms, setPaymentForms] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);

  //Payment state
  const [payment, setPayment] = useState({
    fiscal_name: "",
    fiscal_number: "",
    cfdi_use: "",
    fiscal_regime: "",
    zip_code: "",
    cfdi_type: "",
    amount: "",
    payment_form: "",
    payment_methods: "",
  });

  //Get the payment data
  useEffect(() => {
    const fetchPaymentData = async () => {
      setloadingPayment(true);
      try {
        const { data } = await paymentsProvider.getPayment(id);
        setCustomerId(data.invoice.customer_id);
        setPayment((prevPayment) => ({
          ...prevPayment,
          amount: data.amount,
          payment_form: data.payment_form,
        }));

        if (data.require_cfdi) {
          setPayment((prevPayment) => ({
            ...prevPayment,
            cfdi_type: data.cfdi_type,
            payment_methods: data.method,
          }));
        }

        const matchingPaymentForm = paymentForms.find(
          (form) => form.Value === data.payment_form
        );
        if (matchingPaymentForm) {
          setSelectedPaymentForm(matchingPaymentForm);
        }

        const matchingCfdiType = cfdiTypes.find(
          (type) => type.Value === data.cfdi_type
        );
        if (matchingCfdiType) {
          setSelectedCfdiType(matchingCfdiType);
        }

        const matchingPaymentMethod = paymentMethods.find(
          (method) => method.Value === data.method
        );
        if (matchingPaymentMethod) {
          setSelectedPaymentMethods(matchingPaymentMethod);
        }
      } catch (error) {
        setFormErrors("Error al cargar los datos del pago.");
      } finally {
        setloadingPayment(false);
      }
    };

    fetchPaymentData();
  }, [id, paymentForms, cfdiTypes, paymentMethods]);

  //Get the customer data
  useEffect(() => {
    const fetchCustomerData = async () => {
      if (customerId) {
        setloadingCustomer(true);
        try {
          const { data } = await customersProvider.getCustomer(customerId);
          setPayment((prevPayment) => ({
            ...prevPayment,
            fiscal_name: data.fiscal_name,
            fiscal_number: data.fiscal_number,
            cfdi_use: data.cfdi_use,
            fiscal_regime: data.fiscal_regime,
            zip_code: data.zip_code,
          }));

          const matchingCfdiUse = cfdiUses.find(
            (use) => use.Value === data.cfdi_use
          );
          if (matchingCfdiUse) {
            setSelectedCfdiUse(matchingCfdiUse);
          }

          const matchingFiscalRegimen = fiscalRegimens.find(
            (regimen) => regimen.Value === data.fiscal_regime
          );
          if (matchingFiscalRegimen) {
            setSelectedFiscalRegimen(matchingFiscalRegimen);
          }
        } catch (error) {
          setFormErrors("Error al cargar los datos del cliente.");
        } finally {
          setloadingCustomer(false);
        }
      }
    };

    fetchCustomerData();
  }, [customerId, cfdiUses, fiscalRegimens]);

  //Get the CFDI uses and fiscal regimens
  useEffect(() => {
    const fetchCfdiData = async () => {
      if (payment.fiscal_number) {
        setloadingCfdi(true);
        try {
          const cfdiUsesResponse = await invoicesProvider.getCfdiUses(
            payment.fiscal_number
          );
          setCfdiUses(cfdiUsesResponse.data);

          const fiscalRegimensResponse =
            await invoicesProvider.getFiscalRegimens(payment.fiscal_number);
          setFiscalRegimens(fiscalRegimensResponse.data);
        } catch (error) {
          setFormErrors("Error al cargar los datos fiscales.");
        } finally {
          setloadingCfdi(false);
        }
      }
    };

    fetchCfdiData();
  }, [payment.fiscal_number]);

  //Get the CFDI types, payment forms and payment methods
  useEffect(() => {
    const fetchCfdiPaymentData = async () => {
      setloadingCfdiPayment(true);
      try {
        const cfdiTypesResponse = await invoicesProvider.getCfdiTypes();
        setCfdiTypes(cfdiTypesResponse.data);

        const paymentFormsResponse = await invoicesProvider.getPaymentForms();
        setPaymentForms(paymentFormsResponse.data);

        const paymentMethodsResponse =
          await invoicesProvider.getPaymentMethods();
        setPaymentMethods(paymentMethodsResponse.data);
      } catch (error) {
        setFormErrors("Error al cargar los datos de CFDI y formas de pago.");
      } finally {
        setloadingCfdiPayment(false);
      }
    };

    fetchCfdiPaymentData();
  }, []);

  //Handle the changes in the form
  const handleCfdiUseChange = (_ev, value) => {
    if (value) {
      setPayment({ ...payment, cfdi_use: value.Value });
      setSelectedCfdiUse(value);
      setIsModified(true);
    } else {
      setPayment({ ...payment, cfdi_use: "" });
      setSelectedCfdiUse(null);
      setIsModified(true);
    }
  };

  const handleFiscalRegimenChange = (_ev, value) => {
    if (value) {
      setPayment({ ...payment, fiscal_regime: value.Value });
      setSelectedFiscalRegimen(value);
    } else {
      setPayment({ ...payment, fiscal_regime: "" });
      setSelectedFiscalRegimen(null);
    }
  };

  const handleCfdiTypeChange = (_ev, value) => {
    if (value) {
      setPayment({ ...payment, cfdi_type: value.Value });
      setSelectedCfdiType(value);
    } else {
      setPayment({ ...payment, cfdi_type: "" });
      setSelectedCfdiType(null);
    }
  };

  const handlePaymentFormChange = (_ev, value) => {
    if (value) {
      setPayment({ ...payment, payment_form: value.Value });
      setSelectedPaymentForm(value);
      setIsModified(true);
    } else {
      setPayment({ ...payment, payment_form: "" });
      setSelectedPaymentForm(null);
      setIsModified(true);
    }
  };

  const handlePaymentMethodsChange = (_ev, value) => {
    if (value) {
      setPayment({ ...payment, payment_methods: value.Value });
      setSelectedPaymentMethods(value);
      setIsModified(true);
    } else {
      setPayment({ ...payment, payment_methods: "" });
      setSelectedPaymentMethods(null);
      setIsModified(true);
    }
  };

  const formatAmount = (amount) => {
    if (!amount) return "";
    return currencyFormatter.format(amount);
  };

  //Handling the update and invoice generation
  const handleUpdateClick = () => {
    if (
      !payment.cfdi_use ||
      !payment.payment_form ||
      !payment.payment_methods
    ) {
      setFormErrors("Por favor, complete todos los campos requeridos.");
      return;
    }

    const customerParams = {
      cfdi_use: payment.cfdi_use,
    };

    const paymentParams = {
      payment_form: payment.payment_form,
      method: payment.payment_methods,
    };

    //Updating the customer data
    customersProvider
      .updateCustomer(customerId, customerParams)
      .then(({ data }) => {
        setIsModified(false); //Marking the form as not modified
        setIsInvoiceGenerated(false); //Marking the invoice as not generated
      })
      .catch((error) => {
        let errorMsg =
          "Error al actualizar los datos del cliente. Por favor, inténtelo de nuevo.";
        if (error.response && error.response.data) {
          errorMsg = error.response.data.join(", ");
        }
        setFormErrors(errorMsg);
      });

    //Updating the payment data
    paymentsProvider
      .updatePayment(id, paymentParams)
      .then(({ data }) => {
        setIsModified(false); //Marking the form as not modified
        setIsInvoiceGenerated(false); //Marking the invoice as not generated
      })
      .catch((error) => {
        let errorMsg =
          "Error al actualizar los datos de pago. Por favor, inténtelo de nuevo.";
        if (error.response && error.response.data) {
          errorMsg = error.response.data.join(", ");
        }
        setFormErrors(errorMsg);
      });
  };

  const handleGenerateInvoiceClick = () => {
    paymentsProvider
      .createCfdi(id)
      .then(({ data }) => {
        setIsInvoiceGenerated(true); //Marking the invoice as generated
      })
      .catch((error) => {
        let errorMsg =
          "Error al generar la factura. Por favor, inténtelo de nuevo.";
        if (error.response && error.response.data) {
          errorMsg = error.response.data.join(", ");
        }
        setFormErrors(errorMsg);
      });
  };

  //Rendering the page
  return (
    <MainLayout title="Generar Factura">
      {loadingPayment ||
      loadingCustomer ||
      loadingCfdi ||
      loadingCfdiPayment ? (
        <MKBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="50vh"
        >
          <VideoLoader />
        </MKBox>
      ) : (
        <MKBox component="section" py={12}>
          <Container>
            <Grid container item xs={12} lg={7} sx={{ mx: "auto" }}>
              <MKBox width="100%" component="form">
                {formErrors && (
                  <MKAlert color="error" dismissible={true}>
                    {formErrors}
                  </MKAlert>
                )}
                <MKBox p={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        variant="standard"
                        label="Razón Social"
                        InputLabelProps={{ shrink: !!payment.fiscal_name }}
                        fullWidth
                        name="fiscal_name"
                        value={payment.fiscal_name}
                        disabled={true}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        variant="standard"
                        label="RFC"
                        fullWidth
                        name="fiscal_number"
                        required={true}
                        value={payment.fiscal_number}
                        disabled={true}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Autocomplete
                        value={selectedCfdiUse}
                        options={cfdiUses}
                        getOptionLabel={(option) =>
                          option
                            ? `[${option.Value}] ${option.Name}`
                            : "Uso de CFDI"
                        }
                        renderInput={(params) => (
                          <MKInput
                            {...params}
                            variant="standard"
                            label="Uso de CFDI"
                            fullWidth
                          />
                        )}
                        onChange={handleCfdiUseChange}
                        fullWidth
                        isOptionEqualToValue={(option, value) =>
                          option.Value === value.Value
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Autocomplete
                        value={selectedFiscalRegimen}
                        options={fiscalRegimens}
                        getOptionLabel={(option) =>
                          option
                            ? `[${option.Value}] ${option.Name}`
                            : "Régimen fiscal"
                        }
                        renderInput={(params) => (
                          <MKInput
                            {...params}
                            variant="standard"
                            label="Régimen fiscal"
                            fullWidth
                          />
                        )}
                        onChange={handleFiscalRegimenChange}
                        disabled={!!selectedFiscalRegimen}
                        fullWidth
                        isOptionEqualToValue={(option, value) =>
                          option.Value === value.Value
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        variant="standard"
                        label="Código Postal"
                        InputLabelProps={{ shrink: !!payment.zip_code }}
                        fullWidth
                        name="zip_code"
                        value={payment.zip_code}
                        disabled={true}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Autocomplete
                        value={selectedCfdiType}
                        options={cfdiTypes}
                        getOptionLabel={(option) =>
                          option
                            ? `[${option.Value}] ${option.Name}`
                            : "Tipo de CFDI"
                        }
                        renderInput={(params) => (
                          <MKInput
                            {...params}
                            variant="standard"
                            label="Tipo de CFDI"
                            fullWidth
                          />
                        )}
                        onChange={handleCfdiTypeChange}
                        fullWidth
                        disabled={!!selectedCfdiType}
                        isOptionEqualToValue={(option, value) =>
                          option.Value === value.Value
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        variant="standard"
                        label="Cantidad"
                        fullWidth
                        name="amount"
                        value={formatAmount(payment.amount)}
                        disabled={true}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Autocomplete
                        value={selectedPaymentForm}
                        options={paymentForms}
                        getOptionLabel={(option) =>
                          option
                            ? `[${option.Value}] ${option.Name}`
                            : "Forma de pago"
                        }
                        renderInput={(params) => (
                          <MKInput
                            {...params}
                            variant="standard"
                            label="Forma de pago"
                            fullWidth
                          />
                        )}
                        onChange={handlePaymentFormChange}
                        fullWidth
                        isOptionEqualToValue={(option, value) =>
                          option.Value === value.Value
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Autocomplete
                        value={selectedPaymentMethods}
                        options={paymentMethods}
                        getOptionLabel={(option) =>
                          option
                            ? `[${option.Value}] ${option.Name}`
                            : "Método de pago"
                        }
                        renderInput={(params) => (
                          <MKInput
                            {...params}
                            variant="standard"
                            label="Método de pago"
                            fullWidth
                          />
                        )}
                        onChange={handlePaymentMethodsChange}
                        fullWidth
                        isOptionEqualToValue={(option, value) =>
                          option.Value === value.Value
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid container item justifyContent="center" xs={12} my={2}>
                    <MKButton
                      type="submit"
                      color="primary"
                      fullWidth
                      disabled={!isModified}
                      onClick={handleUpdateClick}
                    >
                      Actualizar datos
                    </MKButton>
                  </Grid>
                  <Grid container item justifyContent="center" xs={12} my={2}>
                    <MKButton
                      type="submit"
                      color="primary"
                      fullWidth
                      disabled={isModified || isInvoiceGenerated}
                      onClick={handleGenerateInvoiceClick}
                    >
                      Generar factura
                    </MKButton>
                  </Grid>
                </MKBox>
              </MKBox>
            </Grid>
          </Container>
        </MKBox>
      )}
    </MainLayout>
  );
}

export default InvoicePaymentPage;
