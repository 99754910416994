// React imports
import { useCallback, useMemo, useState } from "react";

// External libraries
import { Link, useLocation } from "react-router-dom";

// Layouts
import MainLayout from "layouts/mainLayout";

// Material Kit 2 PRO React components
import MKButton from "components/MKButton";
import Toolbar from "components/Toolbar";

// Providers
import RentalsTable from "pages/Rentals/table";
import rentalsProvider from "providers/rentalsProvider";

function RentalsPage() {
  // Loading state for the table
  const [loading, setLoading] = useState(true);

  // Converts URL search parameters to an object with provided suffix.
  const location = useLocation();
  const searchParams = useMemo(() => {
    const params = new URLSearchParams(location.search);
    return Array.from(params.entries()).reduce((acc, [key, value]) => {
      acc[`${key}`] = value;
      return acc;
    }, {});
  }, [location.search]);

  // Query state for searching rentals
  const [query, setQuery] = useState(searchParams);

  const fetchData = useCallback(
    async function (page) {
      setLoading(true);
      const {
        data: { data: rentalsData, total },
      } = await rentalsProvider.getRentals(page + 1, query);
      setLoading(false);
      return { rentals: rentalsData, total };
    },
    [query]
  );

  return (
    <MainLayout title="Contratos">
      <Toolbar
        setQuery={setQuery}
        ransackQuery="unit_name_or_user_last_name_or_user_first_name_or_status_cont"
        newItemButton={
          <MKButton
            color="primary"
            size="small"
            variant="contained"
            component={Link}
            to="/rentals/new"
          >
            + Agregar Contrato
          </MKButton>
        }
      />
      <RentalsTable fetchData={fetchData} loading={loading} />
    </MainLayout>
  );
}

export default RentalsPage;
