import axios from "axios";
import { getCurrentUserToken } from "utils";

class InvoicesProvider {
  axiosClient = axios.create({
    baseURL: `${process.env.REACT_APP_BASE_API_URL}/invoices`,
  });

  facturamaClient = axios.create({
    baseURL: `${process.env.REACT_APP_BASE_API_URL}/facturama`,
  });

  getInvoices(page = null, query = null) {
    const token = getCurrentUserToken();
    return this.axiosClient.get("/", {
      params: { q: JSON.stringify(query), page: page },
      headers: { "X-USER-TOKEN": token },
    });
  }

  getInvoice(id) {
    const token = getCurrentUserToken();
    return this.axiosClient.get(`/${id}`, {
      headers: { "X-USER-TOKEN": token },
    });
  }

  createInvoice(invoiceParams) {
    const token = getCurrentUserToken();
    return this.axiosClient.post("/", invoiceParams, {
      headers: { "X-USER-TOKEN": token },
    });
  }

  deleteInvoice(invoiceId) {
    const token = getCurrentUserToken();
    return this.axiosClient.delete(`/${invoiceId}`, {
      headers: { "X-USER-TOKEN": token },
    });
  }

  getInvoicePayments(invoiceId, page = null, query = null) {
    const token = getCurrentUserToken();
    return this.axiosClient.get(`/${invoiceId}/payments`, {
      params: { q: JSON.stringify(query), page: page },
      headers: { "X-USER-TOKEN": token },
    });
  }

  getFiscalRegimens(rfc) {
    const token = getCurrentUserToken();
    return this.facturamaClient.get("/regimens", {
      params: { rfc },
      headers: { "X-USER-TOKEN": token },
    });
  }

  getCfdiUses(rfc) {
    const token = getCurrentUserToken();
    return this.facturamaClient.get("/cfdi_uses", {
      params: { rfc },
      headers: { "X-USER-TOKEN": token },
    });
  }

  getPaymentMethods() {
    const token = getCurrentUserToken();
    return this.facturamaClient.get("/payment_methods", {
      headers: { "X-USER-TOKEN": token },
    });
  }

  getPaymentForms() {
    const token = getCurrentUserToken();
    return this.facturamaClient.get("/payment_forms", {
      headers: { "X-USER-TOKEN": token },
    });
  }

  getCfdiTypes() {
    const token = getCurrentUserToken();
    return this.facturamaClient.get("/cfdi_types", {
      headers: { "X-USER-TOKEN": token },
    });
  }

  updateInvoice(invoiceId, invoiceParams) {
    const token = getCurrentUserToken();
    return this.axiosClient.put(`/${invoiceId}`, invoiceParams, {
      headers: { "X-USER-TOKEN": token },
    });
  }
}

const invoicesProviderInstance = new InvoicesProvider();
export default invoicesProviderInstance;
