import axios from "axios";
import { getCurrentUserToken } from "utils";

class ProductsProvider {
  axiosClient = axios.create({
    baseURL: `${process.env.REACT_APP_BASE_API_URL}/products`,
  });

  getProducts(page = null, query = null) {
    const token = getCurrentUserToken();
    return this.axiosClient.get("/", {
      params: { q: JSON.stringify(query), page: page },
      headers: { "X-USER-TOKEN": token },
    });
  }

  getProduct(id) {
    const token = getCurrentUserToken();
    return this.axiosClient.get(`/${id}`, {
      headers: { "X-USER-TOKEN": token },
    });
  }

  createProduct(productParams) {
    const token = getCurrentUserToken();
    return this.axiosClient.post("/", productParams, {
      headers: { "X-USER-TOKEN": token },
    });
  }

  updateProduct(id, productParams) {
    const token = getCurrentUserToken();
    return this.axiosClient.put(`/${id}`, productParams, {
      headers: { "X-USER-TOKEN": token },
    });
  }

  deleteProduct(productId) {
    const token = getCurrentUserToken();
    return this.axiosClient.delete(`/${productId}`, {
      headers: { "X-USER-TOKEN": token },
    });
  }
}

const productProviderInstance = new ProductsProvider();
export default productProviderInstance;
