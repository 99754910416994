import axios from "axios";
import { getCurrentUserToken } from "utils";

class DashboardProvider {
  axiosClient = axios.create({
    baseURL: `${process.env.REACT_APP_BASE_API_URL}/dashboard`,
  });

  getIndex() {
    const token = getCurrentUserToken();
    return this.axiosClient.get("/index", {
      headers: { "X-USER-TOKEN": token },
    });
  }

  getDateData(start_date, end_date) {
    const token = getCurrentUserToken();
    return this.axiosClient.get("/index_by_date", {
      params: { start_date, end_date },
      headers: { "X-USER-TOKEN": token },
    });
  }
}

const dashboardProviderInstance = new DashboardProvider();
export default dashboardProviderInstance;
