/**
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import MainLayout from "layouts/mainLayout";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKAlert from "components/MKAlert";

// Providers
import subsidiaryProvider from "providers/subsidiaryProvider";
import invoicesProvider from "providers/invoicesProvider";
import SessionContext from "contexts/SessionContext";
import MKTypography from "components/MKTypography";
import { Divider } from "@mui/material";

function EditSubsidiaryPage() {
  const { currentUser, setCurrentUser } = useContext(SessionContext);
  const id = currentUser.subsidiary.id;
  const navigate = useNavigate();
  const [fiscalNumber, setFiscalNumber] = useState("");
  const [invoiceDayMethod, setInvoiceDayMethod] = useState("");
  const [fiscalRegimens, setFiscalRegimens] = useState([]);
  const [loadingRfc, setLoadingRfc] = useState(true);
  const [formErrors, setFormErrors] = useState("");
  const [selectedRegime, setSelectedRegime] = useState(null);
  const [subsidiary, setSubsidiary] = useState({
    name: "",
    folios: "",
    street: "",
    exterior_number: "",
    interior_number: "",
    neighborhood: "",
    city: "",
    state: "",
    zip_code: "",
    fiscal_name: "",
    fiscal_number: "",
    fiscal_regime: "",
    billing_name: "",
    billing_account: "",
    billing_bank: "",
    billing_clabe: "",
    email: "",
    payment_deadline_days: "",
    invoice_day_method: "",
    access_deadline_days: "",
  });

  useEffect(() => {
    async function fetchSubsidiaryData() {
      try {
        const { data } = await subsidiaryProvider.getSubsidiary(id);
        setSubsidiary(data);
        setFiscalNumber(data.fiscal_number);
        setInvoiceDayMethod(data.invoice_day_method);
      } catch (error) {
        setFormErrors("Error al cargar los datos de la sucursal");
      }
    }
    fetchSubsidiaryData();
  }, [id]);

  useEffect(() => {
    async function fetchFiscalRegimens() {
      setLoadingRfc(true);
      const { data: regimensData } = await invoicesProvider.getFiscalRegimens(
        fiscalNumber
      );
      setFiscalRegimens(regimensData);
      const initialRegime = regimensData.find(
        (regimen) => regimen.Value === subsidiary.fiscal_regime
      );
      setSelectedRegime(initialRegime);
      setLoadingRfc(false);
    }
    fetchFiscalRegimens();
  }, [fiscalNumber, subsidiary.fiscal_regime]);

  const submitForm = async (event) => {
    event.preventDefault();
    try {
      await subsidiaryProvider.updateSubsidiary(id, subsidiary);
      setCurrentUser({ ...currentUser, subsidiary });
      navigate("/subsidiaries/edit", {
        state: { feedback: "Sucursal actualizada correctamente" },
      });
    } catch (error) {
      let errorMsg = "Error en el servidor, favor de reportar el error";
      if (error.response && error.response.data) {
        errorMsg = error.response.data.join(", ");
      }
      setFormErrors(errorMsg);
    }
  };

  const handleInputChange = (event) => {
    event.persist();
    const { name, value } = event.target;

    setSubsidiary({
      ...subsidiary,
      [name]: value,
    });
  };

  const handleFiscalNumberChange = (event) => {
    event.persist();
    const { value } = event.target;

    setSubsidiary({
      ...subsidiary,
      fiscal_number: value.toUpperCase(),
    });

    setFiscalNumber(value.toUpperCase());
  };

  const handleFiscalRegimeChange = (_ev, value) => {
    if (value) {
      const { Value: selectedValue } = value;

      setSubsidiary({
        ...subsidiary,
        fiscal_regime: selectedValue,
      });
      setSelectedRegime(value);
    }
  };

  const handleInvoiceDayMethodChange = (_ev, value) => {
    setSubsidiary({
      ...subsidiary,
      invoice_day_method: value,
    });

    setInvoiceDayMethod(value);
  };

  return (
    <MainLayout title="Editar Sucursal">
      <MKBox component="section" py={12}>
        <Container>
          <Grid container item xs={12} lg={7} sx={{ mx: "auto" }}>
            <MKBox width="100%" component="form" onSubmit={submitForm}>
              {formErrors && (
                <MKAlert color="error" dismissible={true}>
                  {formErrors}
                </MKAlert>
              )}
              <MKBox p={3}>
                <MKBox mb={3}>
                  <MKTypography variant="h4">Información general</MKTypography>
                  <Divider />
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        variant="standard"
                        label="Nombre"
                        fullWidth
                        name="name"
                        required={true}
                        value={subsidiary.name}
                        onChange={handleInputChange}
                        disabled={true}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        variant="standard"
                        label="Correo electrónico"
                        fullWidth
                        name="email"
                        required={true}
                        value={subsidiary.email}
                        onChange={handleInputChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        variant="standard"
                        label="Calle"
                        fullWidth
                        name="street"
                        value={subsidiary.street}
                        onChange={handleInputChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        variant="standard"
                        label="Número exterior"
                        fullWidth
                        name="exterior_number"
                        value={subsidiary.exterior_number}
                        onChange={handleInputChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        variant="standard"
                        label="Número interior"
                        fullWidth
                        name="interior_number"
                        value={subsidiary.interior_number}
                        onChange={handleInputChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        variant="standard"
                        label="Colonia"
                        fullWidth
                        name="neighborhood"
                        value={subsidiary.neighborhood}
                        onChange={handleInputChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        variant="standard"
                        label="Ciudad"
                        fullWidth
                        name="city"
                        value={subsidiary.city}
                        onChange={handleInputChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        variant="standard"
                        label="Estado"
                        fullWidth
                        name="state"
                        value={subsidiary.state}
                        onChange={handleInputChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        variant="standard"
                        label="Código Postal"
                        fullWidth
                        name="zip_code"
                        inputProps={{
                          maxLength: 5,
                          minLength: 5,
                        }}
                        required={true}
                        value={subsidiary.zip_code}
                        onChange={handleInputChange}
                      />
                    </Grid>
                  </Grid>
                </MKBox>
                <MKBox mb={3}>
                  <MKTypography variant="h4">
                    Configuración general
                  </MKTypography>
                  <Divider />
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        variant="standard"
                        label="Límite de días para pagar cobro"
                        fullWidth
                        name="payment_deadline_days"
                        type="number"
                        required={true}
                        value={subsidiary.payment_deadline_days}
                        onChange={handleInputChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        variant="standard"
                        label="Límite de días de retraso para bloquear acceso"
                        fullWidth
                        name="access_deadline_days"
                        type="number"
                        required={false}
                        value={subsidiary.access_deadline_days}
                        onChange={handleInputChange}
                        disabled={true}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Autocomplete
                        options={["mes natural", "mes calendario"]}
                        sx={{ width: 300 }}
                        renderInput={(params) => (
                          <MKInput
                            {...params}
                            variant="standard"
                            label="Método de día de generación automática de cobro"
                            required={true}
                          />
                        )}
                        value={invoiceDayMethod || "mes natural"}
                        onChange={handleInvoiceDayMethodChange}
                      />
                    </Grid>
                  </Grid>
                </MKBox>
                <MKBox mb={3}>
                  <MKTypography variant="h4">
                    Información de facturación
                  </MKTypography>
                  <MKTypography variant="h5">
                    Folios: {subsidiary.folios}
                  </MKTypography>
                  <Divider />
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        variant="standard"
                        label="Razón Social"
                        fullWidth
                        name="fiscal_name"
                        required={true}
                        value={subsidiary.fiscal_name}
                        onChange={handleInputChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        variant="standard"
                        label="RFC"
                        fullWidth
                        name="fiscal_number"
                        required={true}
                        value={fiscalNumber ?? ""}
                        onChange={handleFiscalNumberChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Autocomplete
                        options={fiscalRegimens}
                        loading={loadingRfc}
                        getOptionLabel={(option) =>
                          option
                            ? `[${option.Value}] ${option.Name}`
                            : "Régimen fiscal"
                        }
                        sx={{ width: 300 }}
                        renderInput={(params) => (
                          <MKInput
                            {...params}
                            variant="standard"
                            label="Régimen fiscal"
                          />
                        )}
                        value={selectedRegime ?? ""}
                        onChange={handleFiscalRegimeChange}
                        noOptionsText="RFC inválido"
                      />
                    </Grid>
                  </Grid>
                </MKBox>

                <MKBox mb={3}>
                  <MKTypography variant="h4">Información bancaria</MKTypography>
                  <Divider />
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        variant="standard"
                        label="Nombre del banco"
                        fullWidth
                        name="billing_bank"
                        value={subsidiary.billing_bank}
                        onChange={handleInputChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        variant="standard"
                        label="Nombre del titular"
                        fullWidth
                        name="billing_name"
                        value={subsidiary.billing_name}
                        onChange={handleInputChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        variant="standard"
                        label="Número de cuenta"
                        fullWidth
                        name="billing_account"
                        value={subsidiary.billing_account}
                        onChange={handleInputChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        variant="standard"
                        label="CLABE"
                        fullWidth
                        name="billing_clabe"
                        value={subsidiary.billing_clabe}
                        onChange={handleInputChange}
                      />
                    </Grid>
                  </Grid>
                </MKBox>

                <Grid container item justifyContent="center" xs={12} my={2}>
                  <MKButton type="submit" color="primary" fullWidth>
                    Guardar cambios
                  </MKButton>
                </Grid>
              </MKBox>
            </MKBox>
          </Grid>
        </Container>
      </MKBox>
    </MainLayout>
  );
}

export default EditSubsidiaryPage;
