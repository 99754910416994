import axios from "axios";

class AuthProvider {
  axiosClient = axios.create({
    baseURL: `${process.env.REACT_APP_BASE_API_URL}/auth`,
  });

  login(loginData) {
    return this.axiosClient.post("/login", loginData);
  }
}

const authProviderInstance = new AuthProvider();
export default authProviderInstance;
