import axios from "axios";
import { getCurrentUserToken } from "utils";

class CustomersProvider {
  axiosClient = axios.create({
    baseURL: `${process.env.REACT_APP_BASE_API_URL}/customers`,
  });

  getCustomers(page = null, query = null) {
    const token = getCurrentUserToken();
    return this.axiosClient.get("/", {
      params: { q: JSON.stringify(query), page: page },
      headers: { "X-USER-TOKEN": token },
    });
  }

  getCustomer(id) {
    const token = getCurrentUserToken();
    return this.axiosClient.get(`/${id}`, {
      headers: { "X-USER-TOKEN": token },
    });
  }

  createCustomer(customerParams) {
    const token = getCurrentUserToken();
    return this.axiosClient.post("/", customerParams, {
      headers: { "X-USER-TOKEN": token },
    });
  }

  updateCustomer(id, customerParams) {
    const token = getCurrentUserToken();
    return this.axiosClient.put(`/${id}`, customerParams, {
      headers: { "X-USER-TOKEN": token },
    });
  }

  deleteCustomer(id) {
    const token = getCurrentUserToken();
    return this.axiosClient.delete(`/${id}`, {
      headers: { "X-USER-TOKEN": token },
    });
  }

  exportCustomers(query = null) {
    const token = getCurrentUserToken();
    return this.axiosClient.get("/export", {
      params: { q: JSON.stringify(query) },
      responseType: "blob",
      headers: { "X-USER-TOKEN": token, "Content-Type": "application/json" },
    });
  }
}

const customersProviderInstance = new CustomersProvider();
export default customersProviderInstance;
