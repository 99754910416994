import React, { useCallback } from "react";
import colors from "assets/theme/base/colors";

//importar componentes de react-flow
import { NodeToolbar, Position } from "reactflow";

//importar componentes de Material-UI
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import RentIcon from "@mui/icons-material/House";
import EvictIcon from "@mui/icons-material/Block";
import LockIcon from "@mui/icons-material/Lock";
import UnlockIcon from "@mui/icons-material/LockOpen";
import Tooltip from "@mui/material/Tooltip";

// Importar la función de alerta personalizada
import customAlert from "components/Alerts/CustomAlert";

// Importar proveedores y hooks de navegación
import unitsProvider from "providers/unitsProvider";
import rentalsProvider from "providers/rentalsProvider";
import { useNavigate } from "react-router-dom";

const UnitNode = ({ data, isSelected }) => {
  const { label, status, width, height, activeRental, unit_id: id } = data;

  const statusColor = colors.unitStatus[status] || "#000"; // Color por defecto si no hay estado
  const navigate = useNavigate();

  // Función para editar la unidad
  const handleEdit = useCallback(() => {
    navigate(`/units/${id}/edit`);
  }, [id, navigate]);

  // Función para rentar la unidad
  const handleRent = useCallback(() => {
    navigate(`/rentals/new`, { state: { unitId: id } });
  }, [id, navigate]);

  // Función para eliminar la unidad
  const handleDelete = useCallback(() => {
    customAlert({
      title: "¿Estás seguro?",
      text: "Esta acción no se puede deshacer. Se perderá la información de la unidad.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Sí, eliminar",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await unitsProvider.deleteUnit(id);
          customAlert({
            title: "Eliminado!",
            text: "La unidad ha sido eliminada.",
            icon: "success",
            confirmButtonColor: "#3085d6",
          });
        } catch (error) {
          customAlert({
            title: "Error",
            text: "Ocurrió un error al intentar eliminar la unidad.",
            icon: "error",
            confirmButtonColor: "#d33",
          });
        }
      }
    });
  }, [id]);

  // Función para bloquear/desbloquear la unidad
  const handleBlockChange = useCallback(async () => {
    const newStatus = status === "bloqueada" ? "disponible" : "bloqueada";
    await unitsProvider.updateUnit(id, { status: newStatus });
  }, [id, status]);

  // Función para desalojar la unidad
  const handleEvict = useCallback(async () => {
    customAlert({
      title: "¿Estás seguro?",
      text: "Escribe el motivo de desalojo y cancelación de contrato",
      input: "text",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Desalojar",
      cancelButtonText: "Mantener contrato",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await rentalsProvider.updateRental(activeRental.id, {
            status: "cancelado",
            motive: result.value,
          });

          customAlert({
            title: "Unidad desalojada!",
            text: "La unidad ha sido desalojada exitosamente.",
            icon: "success",
          });
        } catch (error) {
          customAlert({
            title: "Error",
            text: "Hubo un problema al cancelar el contrato.",
            icon: "error",
          });
        }
      }
    });
  }, [activeRental]);

  return (
    <div
      style={{
        padding: 10,
        width: width || 200,
        height: height || 200,
        border: `3px solid #fff`,
        borderRadius: 5,
        backgroundColor: statusColor,
        color: "#fff",
        textAlign: "center",
        cursor: "grab",
        opacity: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: `${Math.min(width, height) / 8}px`,
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
      }}
    >
      <strong>{label}</strong>

      <NodeToolbar
        isVisible={isSelected}
        position={Position.Top}
        style={{
          backgroundColor: "rgba(255, 255, 255, 0.8)", // Fondo blanco con opacidad
          borderRadius: 4, // Bordes redondeados para que se vea mejor
        }}
      >
        {/* Botón para Editar */}
        <Tooltip title="Editar" arrow>
          <IconButton size="small" color="primary" onClick={handleEdit}>
            <EditIcon />
          </IconButton>
        </Tooltip>

        {/* Botón para Eliminar */}
        <Tooltip title="Eliminar" arrow>
          <IconButton size="small" color="error" onClick={handleDelete}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>

        {/* Mostrar Botón para Bloquear o Desbloquear */}
        {status === "bloqueada" ? (
          <Tooltip title="Desbloquear" arrow>
            <IconButton
              size="small"
              color="default"
              onClick={handleBlockChange}
            >
              <UnlockIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Bloquear" arrow>
            <IconButton
              size="small"
              color="default"
              onClick={handleBlockChange}
            >
              <LockIcon />
            </IconButton>
          </Tooltip>
        )}

        {/* Botón para Rentar (solo si está disponible) */}
        {status === "disponible" && (
          <Tooltip title="Rentar" arrow>
            <IconButton size="small" color="success" onClick={handleRent}>
              <RentIcon />
            </IconButton>
          </Tooltip>
        )}

        {/* Botón para Desalojar (solo si está rentada o atrasada) */}
        {(status === "rentada" || status === "atrasada") && (
          <Tooltip title="Desalojar" arrow>
            <IconButton size="small" color="warning" onClick={handleEvict}>
              <EvictIcon />
            </IconButton>
          </Tooltip>
        )}
      </NodeToolbar>
    </div>
  );
};

export default React.memo(UnitNode);
