import React from "react";
import { NodeResizer } from "reactflow";

const CustomNode = ({ data, id, selected }) => {
  return (
    <div
      style={{
        backgroundColor: "#fff",
        border: "1px solid black",
        borderRadius: "5px",
        height: "100%",
      }}
    >
      <NodeResizer minWidth={100} minHeight={100} isVisible={selected} />
      <div style={{ padding: "10px" }}>
        <p>{data.label}</p>
      </div>
    </div>
  );
};

export default React.memo(CustomNode);
