import axios from "axios";
import { getCurrentUserToken } from "utils";

class SubsidiaryProvider {
  axiosClient = axios.create({
    baseURL: `${process.env.REACT_APP_BASE_API_URL}/subsidiaries`,
  });

  getSubsidiaries(page = 1, query = {}) {
    const token = getCurrentUserToken();
    return this.axiosClient.get("/", {
      params: { q: JSON.stringify(query), page: page },
      headers: { "X-USER-TOKEN": token },
    });
  }

  getSubsidiary(id) {
    const token = getCurrentUserToken();
    return this.axiosClient.get(`/${id}`, {
      headers: { "X-USER-TOKEN": token },
    });
  }

  updateSubsidiary(id, subsidiaryParams) {
    const token = getCurrentUserToken();
    return this.axiosClient.put(`/${id}`, subsidiaryParams, {
      headers: { "X-USER-TOKEN": token },
    });
  }
}

const subsidiaryProviderInstance = new SubsidiaryProvider();
export default subsidiaryProviderInstance;
