import React, { useEffect, useState } from "react";
import { Box, Typography, Button, MenuItem, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { motion } from "framer-motion";
import colors from "assets/theme/base/colors";
import { convertToMetersString } from "utils";

const CustomNodeSidebar = ({ selectedNode, closeSidebar, updateNodeData }) => {
  const [name, setName] = useState(""); // Estado para el nombre

  // Actualiza el estado cuando el nodo seleccionado cambia
  useEffect(() => {
    if (selectedNode) {
      setName(selectedNode.data.label); // Cambia esto para acceder al label
    }
  }, [selectedNode]);

  const handleUpdate = () => {
    updateNodeData(selectedNode.id, { label: name }); // Actualiza solo el nombre
    closeSidebar();
  };

  if (!selectedNode) return null;

  return (
    <motion.div
      initial={{ x: "100%" }}
      animate={{ x: 0 }}
      exit={{ x: "100%" }}
      transition={{ duration: 0.5 }}
      style={{
        position: "absolute",
        top: 0,
        right: 0,
        width: "30%",
        height: "80vh",
        backgroundColor: "#fff",
        zIndex: 10,
        borderLeft: `1px solid ${colors.grey[300]}`,
      }}
    >
      <Box
        sx={{
          padding: "16px",
          backgroundColor: colors.background.default,
          overflowY: "auto",
          height: "100%",
        }}
      >
        <Button
          onClick={closeSidebar}
          sx={{
            backgroundColor: colors.error.main,
            color: colors.white.main,
            "&:hover": {
              backgroundColor: colors.error.focus,
              color: colors.black.main,
            },
            marginBottom: "10px",
          }}
          startIcon={<CloseIcon />}
        >
          Cerrar
        </Button>

        {/* Mostrar el nombre del nodo seleccionado */}
        <Typography
          variant="h5"
          sx={{ color: colors.primary.main, marginBottom: "16px" }}
        >
          {name || "Sin nombre"} {/* Muestra 'Sin nombre' si está vacío */}
        </Typography>

        {/* Dropdown para editar el nombre */}
        <TextField
          select
          label="Nombre"
          value={name}
          onChange={(e) => setName(e.target.value)}
          fullWidth
          sx={{ marginBottom: "16px" }}
        >
          <MenuItem value="Oficina">Oficina</MenuItem>
          <MenuItem value="Baños">Baños</MenuItem>
          <MenuItem value="Usos multiples">Usos multiples</MenuItem>
        </TextField>

        {/* Output para el piso */}
        <Typography variant="body1" sx={{ marginBottom: "16px" }}>
          Piso: {selectedNode.data.floor}
        </Typography>

        {/* Output para el ancho */}
        <Typography variant="body1" sx={{ marginBottom: "8px" }}>
          Ancho: {convertToMetersString(selectedNode.style.width)}
        </Typography>

        {/* Output para el largo (height por 2D) */}
        <Typography variant="body1" sx={{ marginBottom: "16px" }}>
          Largo: {convertToMetersString(selectedNode.style.height)}
        </Typography>

        {/* Botón para actualizar */}
        <Button
          variant="contained"
          sx={{
            backgroundColor: colors.error.main,
            color: colors.white.main,
            "&:hover": {
              backgroundColor: colors.error.focus,
              color: colors.white.main,
            },
          }}
          fullWidth
          onClick={handleUpdate}
        >
          Actualizar
        </Button>
      </Box>
    </motion.div>
  );
};

export default CustomNodeSidebar;
