/**
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
// import MKTypography from "components/MKTypography";

import DefaultNavbar from "layouts/Navbar";
import MainFooter from "layouts/Footer/MainFooter";

function AuthLayout({ children }) {
  return (
    <MKBox
      height="100vh"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
    >
      <DefaultNavbar transparent />
      <MKBox width="calc(100% - 2rem)" px={1} mx="auto">
        <Grid container spacing={1} justifyContent="center">
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
            {children}
          </Grid>
        </Grid>
      </MKBox>
      <MainFooter />
    </MKBox>
  );
}

// Typechecking props for the AuthLayout
AuthLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthLayout;
