import Swal from "sweetalert2";
import colors from "assets/theme/base/colors"; // Ajusta la ruta según tu estructura

const customAlert = (options) => {
  return Swal.fire({
    ...options,
    confirmButtonColor: colors.primary.main, // Color para el botón de confirmar
    cancelButtonColor: colors.secondary.focus, // Color para el botón de cancelar
    customClass: {
      confirmButton: "swal2-confirm-button", // Clases personalizadas para los botones
      cancelButton: "swal2-cancel-button",
    },
    // Aplicamos estilos en línea a través de la función 'didOpen'
    didOpen: () => {
      // Confirm Button (botón de confirmación)
      const confirmButton = document.querySelector(".swal2-confirm-button");
      confirmButton.style.backgroundColor = "#ffffff"; // Fondo blanco
      confirmButton.style.color = colors.primary.main; // Texto del color primario
      confirmButton.style.border = `1px solid ${colors.primary.main}`; // Borde del mismo color que el texto

      // Hover para el botón de confirmación
      confirmButton.addEventListener("mouseenter", () => {
        confirmButton.style.backgroundColor = colors.primary.main; // Fondo cambia al color primario
        confirmButton.style.color = "#ffffff"; // Texto cambia a blanco
        confirmButton.style.border = "1px solid #ffffff"; // Borde cambia a blanco
      });

      confirmButton.addEventListener("mouseleave", () => {
        confirmButton.style.backgroundColor = "#ffffff"; // Fondo blanco
        confirmButton.style.color = colors.primary.main; // Texto vuelve al color primario
        confirmButton.style.border = `1px solid ${colors.primary.main}`; // Borde vuelve al color primario
      });

      // Cancel Button (botón de cancelación)
      const cancelButton = document.querySelector(".swal2-cancel-button");
      cancelButton.style.backgroundColor = "#ffffff"; // Fondo blanco
      cancelButton.style.color = colors.secondary.focus; // Texto del color secundario
      cancelButton.style.border = `1px solid ${colors.secondary.focus}`; // Borde del mismo color que el texto

      // Hover para el botón de cancelación
      cancelButton.addEventListener("mouseenter", () => {
        cancelButton.style.backgroundColor = colors.secondary.focus; // Fondo cambia al color secundario
        cancelButton.style.color = "#ffffff"; // Texto cambia a blanco
        cancelButton.style.border = "1px solid #ffffff"; // Borde cambia a blanco
      });

      cancelButton.addEventListener("mouseleave", () => {
        cancelButton.style.backgroundColor = "#ffffff"; // Fondo blanco
        cancelButton.style.color = colors.secondary.focus; // Texto vuelve al color secundario
        cancelButton.style.border = `1px solid ${colors.secondary.focus}`; // Borde vuelve al color secundario
      });
    },
  });
};

export default customAlert;
