// React imports
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

// Layouts
import MainLayout from "layouts/mainLayout";

// Material UI components
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

// Custom components
import NavTabs from "components/NavTabs";
import TabPanel from "components/NavTabs/TabPanel";
import VideoLoader from "components/Animation/VideoLoader";

//
import AdministratorsTable from "pages/Users/tabs/administratorsTable";
import ManagersTable from "pages/Users/tabs/managersTable";

// Providers
import usersProvider from "providers/usersProvider";

function UsersPage() {
  const [tab, setTab] = useState(0);
  const [loading, setLoading] = useState(false);

  const [administrators, setAdministrators] = useState([]);
  const [managers, setManagers] = useState([]);
  const [totalAdmins, setTotalAdmins] = useState(0);
  const [totalManagers, setTotalManagers] = useState(0);
  const [pageAdmins, setPageAdmins] = useState(0);
  const [pageManagers, setPageManagers] = useState(0);

  // Converts URL search parameters to an object with provided suffix.
  const location = useLocation();
  const searchParams = useMemo(() => {
    const params = new URLSearchParams(location.search);
    return Array.from(params.entries()).reduce((acc, [key, value]) => {
      acc[`${key}`] = value;
      return acc;
    }, {});
  }, [location.search]);

  // Function to fetch administrators data
  const fetchAdmins = useCallback(async () => {
    setLoading(true);
    try {
      const {
        data: { data: adminsData, total },
      } = await usersProvider.getAdmins(pageAdmins + 1, searchParams);
      setAdministrators(adminsData);
      setTotalAdmins(total);
    } catch (error) {
      console.error("Error fetching admins:", error);
    }
    setLoading(false);
  }, [pageAdmins, searchParams]);

  // Function to fetch managers data
  const fetchManagers = useCallback(async () => {
    setLoading(true);
    try {
      const {
        data: { data: managersData, total },
      } = await usersProvider.getManagers(pageManagers + 1, searchParams);
      setManagers(managersData);
      setTotalManagers(total);
    } catch (error) {
      console.error("Error fetching managers:", error);
    }
    setLoading(false);
  }, [pageManagers, searchParams]);

  // Load data when tab changes or pagination changes
  useEffect(() => {
    if (tab === 0) {
      fetchAdmins();
    } else if (tab === 1) {
      fetchManagers();
    }
  }, [tab, fetchAdmins, fetchManagers]);

  const tabs = [
    { label: "Administradores", icon: "admin_panel_settings" },
    { label: "Gerentes", icon: "groups" },
  ];

  const handleTabChange = (newValue) => {
    setTab(newValue);
  };

  const handlePageChangeAdmins = (newPage) => {
    setPageAdmins(newPage);
  };

  const handlePageChangeManagers = (newPage) => {
    setPageManagers(newPage);
  };

  return (
    <MainLayout title="Usuarios">
      <Grid item xs={12} lg={12}>
        <NavTabs tabs={tabs} onChange={handleTabChange} />
      </Grid>
      <Grid item xs={12} lg={12}>
        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="15vh"
          >
            <VideoLoader />
          </Box>
        ) : (
          <>
            <TabPanel value={tab} index={0}>
              <AdministratorsTable
                administrators={administrators}
                total={totalAdmins}
                page={pageAdmins}
                onPageChange={handlePageChangeAdmins}
                refreshData={fetchAdmins}
              />
            </TabPanel>
            <TabPanel value={tab} index={1}>
              <ManagersTable
                managers={managers}
                total={totalManagers}
                page={pageManagers}
                onPageChange={handlePageChangeManagers}
                refreshData={fetchManagers}
              />
            </TabPanel>
          </>
        )}
      </Grid>
    </MainLayout>
  );
}
export default UsersPage;
