// React imports
import { useCallback, useEffect, useMemo, useState } from "react";

// React Router imports
import { Link, useNavigate } from "react-router-dom";

// @mui/material components
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import PaymentIcon from "@mui/icons-material/Payment";
import VisibilityIcon from "@mui/icons-material/Visibility";

// Material Kit 2 PRO React components
import MKTypography from "components/MKTypography";

// Custom components
import ActionsMenu from "components/Menu";
import VideoLoader from "components/Animation/VideoLoader";
import Table from "components/Table";
import customAlert from "components/Alerts/CustomAlert";

// Importar el proveedor
import invoicesProvider from "providers/invoicesProvider";
import {
  currencyFormatter,
  shortDateFormat,
  calculateBillingPeriod,
} from "utils";
import MKButton from "components/MKButton";

function InvoicesTable({ fetchData, loading }) {
  const [state, setState] = useState({
    invoices: [],
    rows: [],
    total: 0,
    page: 0,
  });

  // React Router hook for navigation
  const navigate = useNavigate();

  const columns = useMemo(
    () => [
      { name: "id", align: "left" },
      { name: "cliente", align: "left" },
      { name: "unidad", align: "left" },
      { name: "periodo de cobro", align: "left" },
      { name: "fecha límite de pago", align: "left" },
      { name: "subtotal", align: "left" },
      { name: "recargo", align: "left" },
      { name: "total", align: "left" },
      { name: "pagado", align: "left" },
      { name: "pagado el", align: "left" },
      { name: "notas", align: "left" },
      { name: "estatus", align: "left" },
      { name: "opciones", align: "left" },
    ],
    []
  );

  const deleteInvoice = useCallback(
    async function (invoiceId) {
      const result = await customAlert({
        title: "¿Estás seguro?",
        text: "Esta acción eliminará el cobro de manera permanente.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sí, eliminar",
        cancelButtonText: "Cancelar",
      });

      if (result.isConfirmed) {
        try {
          await invoicesProvider.deleteInvoice(invoiceId);
          const data = await fetchData(state.page);
          setState((prevState) => ({
            ...prevState,
            invoices: data.invoices,
            total: data.total,
          }));
          customAlert({
            title: "Eliminado",
            text: "El cobro ha sido eliminado.",
            icon: "success",
          });
          navigate("/invoices");
        } catch (error) {
          customAlert({
            title: "Error",
            text: "Hubo un problema al eliminar el cobro.",
            icon: "error",
          });
        }
      }
    },
    [fetchData, state.page, navigate]
  );

  useEffect(() => {
    fetchData(state.page).then(({ invoices, total }) => {
      setState((prevState) => ({ ...prevState, invoices, total }));
    });
  }, [state.page, fetchData]);

  // Handles page change event, updating the current page in the state
  const handlePageChange = useCallback((_ev, page) => {
    setState((prevState) => ({ ...prevState, page }));
  }, []);

  useEffect(() => {
    if (state.invoices && state.invoices.length > 0) {
      setState((prevState) => ({
        ...prevState,
        rows: state.invoices.map((invoice) => ({
          id: (
            <MKTypography variant="button" fontWeight="medium">
              {invoice.id}
            </MKTypography>
          ),
          cliente: (
            <MKButton
              variant="text"
              color="dark"
              sx={{
                padding: 0,
                minWidth: "auto",
                fontSize: "inherit",
                "&:hover": {
                  backgroundColor: "transparent",
                  color: "primary.main",
                },
              }}
              component={Link}
              to={`/customers/${invoice.customer.id}`}
            >
              {`${invoice.customer.first_name} ${invoice.customer.last_name}`}
            </MKButton>
          ),
          unidad: (
            <MKTypography variant="button" fontWeight="medium">
              {`${invoice.unit.floor || ""}${invoice.unit.block || ""} ${
                invoice.unit.name
              }`}
            </MKTypography>
          ),
          "periodo de cobro": (
            <MKTypography variant="button" fontWeight="medium">
              {calculateBillingPeriod(invoice.start_date, invoice.end_date)}
            </MKTypography>
          ),
          "fecha límite de pago": (
            <MKTypography variant="button" fontWeight="medium">
              {shortDateFormat(invoice.payment_deadline)}
            </MKTypography>
          ),
          subtotal: (
            <MKTypography variant="button" fontWeight="medium">
              {currencyFormatter.format(invoice.subtotal)}
            </MKTypography>
          ),
          recargo: (
            <MKTypography variant="button" fontWeight="medium">
              {invoice.surcharge ? `$${invoice.surcharge}` : "Sin recargo"}
            </MKTypography>
          ),
          total: (
            <MKTypography variant="button" fontWeight="medium">
              {currencyFormatter.format(
                Number(invoice.total) + Number(invoice.surcharge)
              )}
            </MKTypography>
          ),
          pagado: (
            <MKTypography variant="button" fontWeight="medium">
              {currencyFormatter.format(invoice.paid_amount)}
            </MKTypography>
          ),
          "pagado el": (
            <MKTypography variant="button" fontWeight="medium">
              {shortDateFormat(invoice.paid_date)}
            </MKTypography>
          ),
          notas: (
            <MKTypography variant="button" fontWeight="medium">
              {invoice.notes || ""}
            </MKTypography>
          ),
          estatus: (
            <MKTypography variant="button" fontWeight="medium">
              {invoice.status}
            </MKTypography>
          ),
          opciones: (
            <Box display="flex" gap={1}>
              <ActionsMenu
                actions={[
                  {
                    text: "Editar",
                    icon: <EditIcon />,
                    onClick: () => navigate(`/invoices/${invoice.id}/edit`),
                  },
                  {
                    text: "Ver Pagos",
                    icon: <VisibilityIcon />,
                    disabled: true,
                    onClick: () => {
                      const defaultTab =
                        invoice.invoice_type === "renta" ? 0 : 1;
                      navigate("/payments", {
                        state: { defaultTab, invoice_id: invoice.id },
                      });
                    },
                  },
                  {
                    text: "Pagar",
                    icon: <PaymentIcon />,
                    onClick: () => {
                      const defaultTab =
                        invoice.invoice_type === "renta" ? 0 : 1;
                      navigate(
                        `/payments/${invoice.id}/new?type=${defaultTab}`
                      );
                    },
                  },

                  {
                    text: "Eliminar",
                    icon: <DeleteIcon />,
                    onClick: () => deleteInvoice(invoice.id),
                  },
                ]}
              />
            </Box>
          ),
        })),
      }));
    } else {
      setState((prevState) => ({ ...prevState, rows: [] }));
    }
  }, [state.invoices, deleteInvoice, navigate]);

  return (
    <>
      <Grid container item xs={12} lg={12}>
        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="15vh"
          >
            <VideoLoader />
          </Box>
        ) : state.rows.length === 0 ? (
          <MKTypography variant="h6" fontWeight="medium">
            No se encontraron cobros
          </MKTypography>
        ) : (
          <Table
            columns={columns}
            rows={state.rows}
            total={state.total}
            page={state.page}
            handlePageChange={handlePageChange}
          />
        )}
      </Grid>
    </>
  );
}

export default InvoicesTable;
