/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import DefaultNavbar from "layouts/Navbar";
import MainFooter from "layouts/Footer/MainFooter";

import { useLocation } from "react-router-dom";
import { Alert, AlertTitle, Snackbar } from "@mui/material";
import { useEffect, useState } from "react";

function BaseLayout({ title, children }) {
  const location = useLocation();
  const [openSnack, setOpenSnack] = useState(false);
  const [alertState, setAlertState] = useState({
    status: "success",
    message: "",
  });

  const { state } = location;

  useEffect(() => {
    if (state && state.feedback) {
      setOpenSnack(true);
      setAlertState({
        status: state.status || "success",
        message: state.feedback,
      });
    }
  }, [state]);

  const handleSnackClose = (_event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  return (
    <MKBox
      display="flex"
      flexDirection="column"
      bgColor="white"
      minHeight="100vh"
    >
      <MKBox bgColor="white" shadow="sm" py={0.25}>
        <DefaultNavbar transparent relative />
      </MKBox>
      <Container sx={{ mt: 6 }}>
        <Snackbar
          open={openSnack}
          autoHideDuration={6000}
          onClose={handleSnackClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          sx={{
            mt: "4rem",
          }}
        >
          <Alert severity={alertState.status}>
            <AlertTitle>
              {alertState.status === "success" ? "Éxito" : "Error"}
            </AlertTitle>
            {alertState.message}
          </Alert>
        </Snackbar>
        <Grid
          container
          flexDirection="column"
          justifyContent="center"
          mx="auto"
        >
          <Grid item xs={12}>
            <MKTypography variant="h3" mb={1}>
              {title}
            </MKTypography>
          </Grid>
          <Grid
            container
            item
            xs={12}
            rowSpacing={4}
            direction="column"
            justifyContent="space-around"
          >
            {children}
          </Grid>
        </Grid>
      </Container>
      <MKBox mt="auto">
        <MainFooter />
      </MKBox>
    </MKBox>
  );
}

// Typechecking props for the BaseLayout
BaseLayout.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default BaseLayout;
