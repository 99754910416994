import axios from "axios";
import { getCurrentUserToken } from "utils";

class PaymentsProvider {
  axiosClient = axios.create({
    baseURL: `${process.env.REACT_APP_BASE_API_URL}/payments`,
  });

  pdfMonkeyClient = axios.create({
    baseURL: "https://api.pdfmonkey.io/api/v1/documents",
  });

  getPayments(page = null, query = null) {
    const token = getCurrentUserToken();
    return this.axiosClient.get("/", {
      params: { q: JSON.stringify(query), page: page },
      headers: { "X-USER-TOKEN": token },
    });
  }

  getPayment(paymentId) {
    const token = getCurrentUserToken();
    return this.axiosClient.get(`/${paymentId}`, {
      headers: { "X-USER-TOKEN": token },
    });
  }

  createPayment(paymentParams) {
    const token = getCurrentUserToken();
    return this.axiosClient.post("/", paymentParams, {
      headers: { "X-USER-TOKEN": token },
    });
  }

  createCfdi(paymentId) {
    const token = getCurrentUserToken();
    return this.axiosClient.post(
      `/${paymentId}/cfdi`,
      {},
      {
        headers: { "X-USER-TOKEN": token },
      }
    );
  }

  deletePayment(paymentId) {
    const token = getCurrentUserToken();
    return this.axiosClient.delete(`/${paymentId}`, {
      headers: { "X-USER-TOKEN": token },
    });
  }

  updatePayment(paymentId, paymentParams) {
    const token = getCurrentUserToken();
    return this.axiosClient.put(`/${paymentId}`, paymentParams, {
      headers: { "X-USER-TOKEN": token },
    });
  }
}

const paymentsProviderInstance = new PaymentsProvider();
export default paymentsProviderInstance;
