import { useState } from "react";

// @mui material components
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";

function NavTabs({ tabs, onChange, active }) {
  const [activeTab, setActiveTab] = useState(active);

  const handleTabType = (_event, newValue) => {
    setActiveTab(newValue);
    onChange(newValue);
  };

  return (
    <AppBar position="static">
      <Tabs value={activeTab} onChange={handleTabType}>
        {tabs &&
          tabs.map((tab, index) => (
            <Tab
              key={index}
              label={tab.label}
              icon={<Icon>{tab.icon || "dashboard"}</Icon>}
            />
          ))}
      </Tabs>
    </AppBar>
  );
}

export default NavTabs;
