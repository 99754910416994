// Material UI components
import Icon from "@mui/material/Icon";

import UsersPage from "pages/Users";
import DashboardPage from "pages/Dashboard";
import CustomersPage from "pages/Customers";
import InvoicesPage from "pages/Invoices";
import PaymentsPage from "pages/Payments";
import RentalsPage from "pages/Rentals";
import UnitsPage from "pages/Units";
import SubsidiariesPage from "pages/Subsidiary";
import EditSubsidiaryPage from "pages/Subsidiary/editSubsidiary";

import NewCustomerPage from "pages/Customers/newCustomer";
import NewPaymentPage from "pages/Payments/newPayment";
import NewRentalPage from "pages/Rentals/newRental";
import NewUnitPage from "pages/Units/newUnit";
import MapPage from "pages/Units/map";
import NewProductPage from "pages/Products/newProduct";

import SignInPage from "pages/SignIn";

import RentalInvoices from "pages/Rentals/rentalInvoices";
import ShowCustomerPage from "pages/Customers/show";
import EditUnitPage from "pages/Units/editUnit";
import EditProductPage from "pages/Products/editProduct";
import EditCustomerPage from "pages/Customers/editCustomer";
import EditRentalPage from "pages/Rentals/editRental";
import InvoicePaymentPage from "pages/Payments/invoicePayment";
import NewInvoicePage from "pages/Invoices/newInvoices";
import EditInvoicePage from "pages/Invoices/editInvoice";
import ProductsPage from "pages/Products";

export const adminRoutes = [
  {
    name: "Usuarios",
    icon: <Icon>group</Icon>,
    route: "/users",
    component: <UsersPage />,
    showInNavbar: true,
  },
  {
    name: "Sucursales",
    icon: <Icon>business</Icon>,
    route: "/subsidiaries",
    component: <SubsidiariesPage />,
    showInNavbar: true,
  },
];

export const managerRoutes = [
  {
    name: "Inicio",
    icon: <Icon>dashboard</Icon>,
    route: "/dashboard",
    component: <DashboardPage />,
    showInNavbar: true,
  },
  {
    name: "Clientes",
    icon: <Icon>groups</Icon>,
    route: "/customers",
    component: <CustomersPage />,
    showInNavbar: true,
  },
  {
    name: "Unidades",
    icon: <Icon>business</Icon>,
    route: "/units",
    component: <UnitsPage />,
    showInNavbar: true,
  },
  {
    name: "Contratos",
    icon: <Icon>description</Icon>,
    route: "/rentals",
    component: <RentalsPage />,
    showInNavbar: true,
  },
  {
    name: "Cobros",
    icon: <Icon>receipt_long</Icon>,
    route: "/invoices",
    component: <InvoicesPage />,
    showInNavbar: true,
  },
  {
    name: "Pagos",
    icon: <Icon>payment</Icon>,
    route: "/payments",
    component: <PaymentsPage />,
    showInNavbar: true,
  },
  {
    name: "Productos",
    icon: <Icon>store_mall_directory</Icon>,
    route: "/products",
    component: <ProductsPage />,
    showInNavbar: true,
  },
  {
    route: "/customers/new",
    component: <NewCustomerPage />,
    showInNavbar: false,
  },
  {
    route: "/rentals/new",
    component: <NewRentalPage />,
    showInNavbar: false,
  },
  {
    route: "/units/new",
    component: <NewUnitPage />,
    showInNavbar: false,
  },
  {
    route: "/products/new",
    component: <NewProductPage />,
    showInNavbar: false,
  },
  {
    route: "/units/map",
    component: <MapPage />,
    showInNavbar: false,
  },
  {
    route: "/rentals/:id/invoices",
    component: <RentalInvoices />,
    showInNavbar: false,
  },
  {
    route: "/products/:id/edit",
    component: <EditProductPage />,
    showInNavbar: false,
  },
  {
    route: "/units/:id/edit",
    component: <EditUnitPage />,
    showInNavbar: false,
  },
  {
    route: "/rentals/:id/edit",
    component: <EditRentalPage />,
    showInNavbar: false,
  },
  {
    mobileName: "Editar Sucursal",
    icon: <Icon>settings</Icon>,
    route: "/subsidiaries/edit",
    component: <EditSubsidiaryPage />,
    showInNavbar: true,
  },
  {
    route: "/invoices/new",
    component: <NewInvoicePage />,
    showInNavbar: false,
  },
  {
    route: "/invoices/:id/edit",
    component: <EditInvoicePage />,
    showInNavbar: false,
  },
];

export const customerRoutes = [
  {
    route: "/payments/new",
    component: <NewPaymentPage />,
    showInNavbar: false,
  },
  {
    route: "/customers/:id",
    component: <ShowCustomerPage />,
    showInNavbar: false,
  },
  {
    route: "/customers/:id/edit",
    component: <EditCustomerPage />,
    showInNavbar: false,
  },
  {
    route: "/payments/:id/create-invoice",
    component: <InvoicePaymentPage />,
    showInNavbar: false,
  },
  {
    route: "/payments/:id/new",
    component: <NewPaymentPage />,
    showInNavbar: false,
  },
];

export const guestRoutes = [
  {
    route: "/sign-in",
    component: <SignInPage />,
    showInNavbar: false,
  },
];
