import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  useContext,
} from "react";
import {
  Background,
  Controls,
  ReactFlow,
  applyNodeChanges,
  ControlButton,
} from "reactflow";
import "reactflow/dist/style.css";

// Importar componentes de la aplicación
import MainLayout from "layouts/mainLayout";
import UnitNode from "components/Nodes/UnitNode";
import CustomNode from "components/Nodes/CustomNode";
import Sidebar from "components/Sidebar/SidebarNode";
import CustomNodeSidebar from "components/Sidebar/CustomSidebar";
import NodeMenu from "components/Sidebar/NodeMenu";
import customAlert from "components/Alerts/CustomAlert";
import VideoLoader from "components/Animation/VideoLoader";

//providers
import subsidiaryProvider from "providers/subsidiaryProvider";
import unitsProvider from "providers/unitsProvider";
import SessionContext from "contexts/SessionContext";

//importar componentes de Material-UI
import SaveIcon from "@mui/icons-material/Save";
import { Grid } from "@mui/material";

// Definir el tipo de nodo fuera del componente
const nodeTypes = {
  unit: UnitNode,
  custom: CustomNode,
};

function MapPage() {
  const { currentUser } = useContext(SessionContext);
  const id = currentUser.subsidiary_id;

  const [nodes, setNodes] = useState([]);
  const [newMappedUnitIds, setNewMappedUnitIds] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [selectedCustomNode, setSelectedCustomNode] = useState(null);
  const [selectedFloor, setSelectedFloor] = useState(null); // Estado para el piso seleccionado
  const reactFlowWrapper = useRef(null); // Referencia para calcular la posición al soltar el nodo
  const [unitNodes, setUnitNodes] = useState([]);

  const [loading, setLoading] = useState(true);

  const [floors, setFloors] = useState([]);

  const onNodesChange = useCallback(
    (changes) => {
      if (
        selectedCustomNode !== null &&
        changes[0].id === selectedCustomNode.id &&
        changes[0].type === "dimensions" &&
        changes[0].resizing
      ) {
        setSelectedCustomNode({
          ...selectedCustomNode,
          style: changes[0].dimensions || selectedCustomNode.style,
        });
      }
      setNodes((nds) => applyNodeChanges(changes, nds));
    },
    [setNodes, selectedCustomNode, setSelectedCustomNode]
  );

  // Cargar pisos, unidades y mapa al montar el componente
  useEffect(() => {
    const fetchAllData = async () => {
      setLoading(true); // Activa el loading antes de comenzar la carga de datos
      try {
        const { data: floorsData } = await unitsProvider.getFloors();
        setFloors(floorsData);

        // Si se obtienen pisos, selecciona el primero (o el más bajo).
        if (floorsData.length > 0) {
          setSelectedFloor(floorsData[0]);
        }

        const {
          data: { data: unitsData },
        } = await unitsProvider.getUnits();

        const { data: subsidiary } = await subsidiaryProvider.getSubsidiary(id);
        if (subsidiary && subsidiary.map) {
          const subsidiaryMap = JSON.parse(subsidiary.map);

          // Actualiza los nodos mapeados con la información más reciente
          const updatedMappedNodes = subsidiaryMap.map((mappedNode) => {
            const correspondingUnit = unitsData.find(
              (unit) => unit.id === mappedNode.data.unit_id && unit.is_mapped
            );

            if (correspondingUnit) {
              return {
                ...mappedNode,
                data: {
                  ...mappedNode.data,
                  status: correspondingUnit.status,
                  active_rental: correspondingUnit.active_rental,
                },
              };
            }
            return mappedNode;
          });

          // Establece los nodos actualizados
          setNodes(updatedMappedNodes);
        } else {
          console.warn("No hay un mapa guardado en la base de datos.");
        }

        const formattedNodes = unitsData
          .filter((unit) => !unit.is_mapped) // Solo incluye unidades no mapeadas
          .map((unit, index) => ({
            id: String(index + 1),
            type: "unit",
            data: {
              label: unit.name,
              status: unit.status,
              width: (unit.width || 3) * 100,
              height: (unit.length || 3) * 100,
              floor: unit.floor,
              block: unit.block,
              default_price: unit.default_price,
              active_rental: unit.active_rental,
              unit_id: unit.id,
            },
            position: { x: 0, y: index * 100 },
          }));

        setUnitNodes(formattedNodes);
      } catch (error) {
        console.error("Error al obtener las unidades o el mapa:", error);
        setLoading(false);
      }
      setLoading(false); // Desactiva el loading cuando se completó la carga de datos
    };

    fetchAllData();
  }, [id]);

  useEffect(() => {
    // Restaurar el mapa guardado cuando se monte el componente
    const fetchMapData = async () => {
      try {
        const { data: subsidiary } = await subsidiaryProvider.getSubsidiary(id);
        if (subsidiary && subsidiary.map) {
          setNodes(JSON.parse(subsidiary.map));
        } else {
          console.warn("No hay un mapa guardado en la base de datos.");
        }
      } catch (error) {
        console.error("Hubo un problema al cargar el mapa:", error);
      }
    };

    fetchMapData();
  }, [id]);

  const handleNodeClick = useCallback(
    (_event, node) => {
      if (node.type === "unit") {
        setSelectedUnit(node.data);
        setSelectedCustomNode(null);
      } else if (node.type === "custom") {
        setSelectedCustomNode(node);
        setSelectedUnit(null);
      }
    },
    [setSelectedUnit, setSelectedCustomNode]
  );

  const closeSidebar = useCallback(() => {
    setSelectedUnit(null);
    setSelectedCustomNode(null);
  }, []);

  const updateNodeData = (id, newData) => {
    setNodes((prevNodes) =>
      prevNodes.map((node) =>
        node.id === id ? { ...node, data: { ...node.data, ...newData } } : node
      )
    );
  };

  // Guardar el estado del mapa en la base de datos
  const handleSave = async () => {
    try {
      const savedMap = JSON.stringify(nodes);
      await Promise.all(
        newMappedUnitIds.map((unitId) =>
          unitsProvider.updateUnit(unitId, { is_mapped: true })
        )
      );

      await subsidiaryProvider.updateSubsidiary(id, { map: savedMap });
      customAlert({
        title: "Guardado",
        text: "El mapa ha sido guardado con éxito.",
        icon: "success",
        confirmButtonText: "Aceptar",
      });
    } catch (error) {
      console.log(error);
      customAlert({
        title: "Error",
        text: "Hubo un problema al guardar el mapa.",
        icon: "error",
        confirmButtonText: "Aceptar",
      });
    }
  };

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();
      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      const draggedNode = JSON.parse(
        event.dataTransfer.getData("application/reactflow")
      );

      if (!draggedNode) return;

      const position = {
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      };

      if (draggedNode.type === "unit") {
        setNewMappedUnitIds((ids) => ids.concat(draggedNode.data.unit_id));
      }

      const newNode = {
        id: `${nodes.length + 1}`,
        type: draggedNode.type,
        position,
        data: {
          label:
            draggedNode.type === "unit" ? draggedNode.data.label : "Oficina",
          floor: selectedFloor, // Asigna el piso actual al nodo nuevo
          status:
            draggedNode.type === "unit"
              ? draggedNode.data.status
              : "disponible",
          ...draggedNode.data,
        },
        style: {
          width: 150, // Valor predeterminado
          height: 150, // Valor predeterminado
        },
      };

      setNodes((nds) => nds.concat(newNode));
    },
    [nodes, selectedFloor] // Incluye selectedFloor como dependencia
  );

  //función para permitir soltar nodos en el área de trabajo
  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);

  // Filtrar los nodos según el piso seleccionado
  const filteredNodes = selectedFloor
    ? nodes.filter((node) => node.data.floor === selectedFloor)
    : nodes;

  // Función para añadir nodos al mapa y actualizar el estado de unidades mapeadas
  const handleAddNode = (node) => {
    setNodes((prevNodes) => [...prevNodes, node]);
    setNewMappedUnitIds((prevIds) => [...prevIds, node.data.unit_id]);
  };

  if (loading) {
    return <VideoLoader />;
  }

  return (
    <MainLayout title="Mapa de Unidades">
      <Grid item style={{ display: "flex", height: "100vh" }}>
        {/* Renderizar NodeMenu en el lado izquierdo */}
        <NodeMenu
          unitNodes={unitNodes.filter(
            (unitNode) => !newMappedUnitIds.includes(unitNode.data.unit_id)
          )}
          selectedFloor={selectedFloor}
          onAddNode={handleAddNode}
        />

        <div
          ref={reactFlowWrapper}
          style={{ flexGrow: 1, position: "relative" }}
        >
          <div
            style={{
              position: "absolute",
              top: "50px",
              left: "10px",
              padding: "8px",
              backgroundColor: "rgba(255, 255, 255, 0.8)",
              borderRadius: "5px",
              zIndex: 1000,
            }}
          >
            <strong>Piso Actual: {selectedFloor}</strong>
          </div>

          <ReactFlow
            nodes={filteredNodes}
            nodeTypes={nodeTypes}
            onNodesChange={onNodesChange}
            onNodeClick={handleNodeClick}
            onDrop={onDrop}
            onDragOver={onDragOver}
            deleteKeyCode={null}
            style={{ height: "100%", width: "100%" }}
            minZoom={0.01}
          >
            <Controls
              position="top-left"
              style={{
                position: "absolute",
                top: "10px",
                left: "50%",
                transform: "translateX(-50%)",
                display: "flex",
                flexDirection: "row",
                gap: "8px",
                zIndex: 1000,
              }}
            >
              {/* Botones para seleccionar el piso */}
              {floors.length > 0 &&
                floors.map((floor) => (
                  <ControlButton
                    key={floor}
                    onClick={() => setSelectedFloor(floor)}
                    title={`Mostrar unidades del piso ${floor}`}
                  >
                    Piso {floor}
                  </ControlButton>
                ))}

              {/* Botón para Guardar */}
              <ControlButton onClick={handleSave} title="Guardar mapa actual">
                <SaveIcon />
              </ControlButton>
            </Controls>
            <Background />
          </ReactFlow>
        </div>
        {selectedUnit && (
          <Sidebar
            unitData={selectedUnit}
            onClose={closeSidebar}
            updateNodeData={updateNodeData}
          />
        )}
        {selectedCustomNode && (
          <CustomNodeSidebar
            selectedNode={selectedCustomNode}
            closeSidebar={closeSidebar}
            updateNodeData={updateNodeData}
            onClose={closeSidebar}
          />
        )}
        {selectedUnit && (
          <Sidebar selectedUnit={selectedUnit} closeSidebar={closeSidebar} />
        )}
      </Grid>
    </MainLayout>
  );
}

export default MapPage;
