// React imports
import { useContext, useEffect, useState } from "react";

// External libraries
import { useLocation, useNavigate } from "react-router-dom";

// Layouts
import MainLayout from "layouts/mainLayout";

// Material UI components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import InputAdornment from "@mui/material/InputAdornment";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKAlert from "components/MKAlert";

// Providers
import unitsProvider from "providers/unitsProvider";
import customersProvider from "providers/customersProvider";
import rentalsProvider from "providers/rentalsProvider";
import SessionContext from "contexts/SessionContext";
import { currencyFormatter } from "utils";

//Custom components
import VideoLoader from "components/Animation/VideoLoader";

function NewRentalPage() {
  // Get the current user from the session context
  const { currentUser } = useContext(SessionContext);

  // Get unitId from the location state
  const locationState = useLocation().state;
  const { unitId } = locationState || {};

  // React Router hook for navigation
  const navigate = useNavigate();

  // Loading state for the form
  const [loading, setLoading] = useState(true);

  // State for managing form errors
  const [formErrors, setFormErrors] = useState("");

  const nextYear = new Date();
  nextYear.setFullYear(nextYear.getFullYear() + 1);

  const [focusedDiscount, setFocusedDiscount] = useState(false);
  const [focusedDeposit, setFocusedDeposit] = useState(false);

  const handleFocusDiscount = () => setFocusedDiscount(true);
  const handleBlurDiscount = () => setFocusedDiscount(false);

  const handleFocusDeposit = () => setFocusedDeposit(true);
  const handleBlurDeposit = () => setFocusedDeposit(false);

  // State for selected unit and customer
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  // Options for units and customers
  const [units, setUnits] = useState([]);
  const [customers, setCustomers] = useState([]);

  const [rental, setRental] = useState({
    unit_id: "",
    user_id: "",
    deposit: "",
    monthly_rent: "",
    start_date: new Date().toISOString().split("T")[0],
    end_date: nextYear.toISOString().split("T")[0],
    status: "al corriente",
    subsidiary_id: currentUser.subsidiary.id,
    user_guarantee_name: "",
    stored_goods: "",
    discount: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const [unitsData, customersData] = await Promise.all([
          unitsProvider.getUnits(null, { status_not_eq: "rentada" }),
          customersProvider.getCustomers(null, { status_not_eq: "bloqueado" }),
        ]);

        setUnits(unitsData.data.data);
        setCustomers(customersData.data.data);
        // If unitId is provided, select the unit
        if (unitId) {
          const unit = unitsData.data.data.find((unit) => unit.id === unitId);
          if (unit) {
            setSelectedUnit({
              label: `${unit.floor ?? ""}${unit.block ?? ""} ${unit.name}`,
              id: unit.id,
              default_price: unit.default_price,
            });
            setRental((prevRental) => ({
              ...prevRental,
              unit_id: unit.id,
              monthly_rent:
                unit.default_price * (1 - Number(rental.discount / 100)),
            }));
          }
        }
      } catch (error) {
        console.error("Error fetching customer or invoice data", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
    // ignore the warning for rental.discount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unitId]);

  // Handles changes in the form input fields and updates rental state
  const handleInputChange = ({ target: { name, value } }) => {
    if (name === "discount") {
      let validValue = Math.max(0, Math.min(100, parseInt(value) || 0));
      setRental((prevRental) => ({
        ...prevRental,
        [name]: validValue,
      }));

      if (selectedUnit && selectedUnit.default_price && validValue !== "") {
        const discountAmount = (selectedUnit.default_price * validValue) / 100;
        const newDiscountedPrice = selectedUnit.default_price - discountAmount;

        setRental((prevRental) => ({
          ...prevRental,
          monthly_rent: newDiscountedPrice.toFixed(2),
        }));
      } else {
        setRental((prevRental) => ({
          ...prevRental,
          monthly_rent: "",
        }));
      }
    } else if (name === "deposit") {
      let validValue = Math.max(0, Number(value) || 0);
      setRental((prevRental) => ({
        ...prevRental,
        [name]: validValue,
      }));
    } else {
      setRental((prevRental) => ({
        ...prevRental,
        [name]: value,
      }));
    }
  };

  // Handles changes in the unit selection field and updates rental state
  const handleUnitChange = (_ev, value) => {
    if (value && value.id) {
      setSelectedUnit(value);
      setRental((prevRental) => ({
        ...prevRental,
        unit_id: value.id,
        monthly_rent: value.default_price * (1 - Number(rental.discount / 100)),
      }));
    } else {
      setSelectedUnit(null);
      setRental((prevRental) => ({
        ...prevRental,
        unit_id: "",
        monthly_rent: "",
      }));
    }
  };

  // Handles changes in the customer selection field and updates rental state
  const handleCustomerChange = (_ev, value) => {
    if (value && value.id) {
      setSelectedCustomer(value);
      setRental((prevRental) => ({
        ...prevRental,
        user_id: value.id,
      }));
    } else {
      setSelectedCustomer(null);
      setRental((prevRental) => ({
        ...prevRental,
        user_id: "",
      }));
    }
  };

  // Submits the form after validating the data and handling errors
  const submitForm = async (event) => {
    event.preventDefault();

    if (!selectedUnit || !selectedCustomer) {
      setFormErrors("Favor de seleccionar una unidad y un cliente");
      return;
    }

    try {
      await rentalsProvider.createRental(rental);
      navigate("/rentals", {
        state: { feedback: "Contrato creado correctamente" },
      });
    } catch (error) {
      let errorMsg = "Error en el servidor, favor de reportar el error";
      if (error.response && error.response.data) {
        errorMsg = error.response.data.join(", ");
      }
      setFormErrors(errorMsg);
    }
  };

  return (
    <MainLayout title="Nuevo Contrato">
      {loading ? (
        <MKBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="50vh"
        >
          <VideoLoader />
        </MKBox>
      ) : (
        <MKBox component="section" py={12}>
          <Container>
            <Grid container item xs={12} lg={7} sx={{ mx: "auto" }}>
              <MKBox width="100%" component="form" onSubmit={submitForm}>
                {formErrors && (
                  <MKAlert color="error" dismissible={true}>
                    {formErrors}
                  </MKAlert>
                )}
                {selectedUnit && (
                  <MKBox mb={3}>
                    <MKAlert color="info">
                      <MKBox color="white" fontSize="1rem">
                        Precio de la unidad seleccionada:
                        {currencyFormatter.format(
                          selectedUnit.default_price
                        )}{" "}
                        &nbsp;
                      </MKBox>
                      {rental.discount > 0 && (
                        <MKBox color="white" fontSize="1rem">
                          Precio con descuento aplicado:{" "}
                          {currencyFormatter.format(rental.monthly_rent)}
                        </MKBox>
                      )}
                    </MKAlert>
                  </MKBox>
                )}
                <MKBox p={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <Autocomplete
                        value={selectedUnit}
                        options={units.map((unit) => ({
                          label: `${unit.floor ?? ""}${unit.block ?? ""} ${
                            unit.name
                          }`,
                          id: unit.id,
                          default_price: unit.default_price,
                        }))}
                        getOptionLabel={(option) => option?.label || "Unidad"}
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        renderInput={(params) => (
                          <MKInput
                            {...params}
                            variant="standard"
                            required
                            label="Unidad"
                            fullWidth
                          />
                        )}
                        onChange={handleUnitChange}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Autocomplete
                        value={selectedCustomer}
                        options={customers.map((customer) => ({
                          label: `${customer.first_name} ${customer.last_name}`,
                          id: customer.id,
                        }))}
                        getOptionLabel={(option) => option?.label || "Cliente"}
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        renderInput={(params) => (
                          <MKInput
                            {...params}
                            variant="standard"
                            required
                            label="Cliente"
                            fullWidth
                          />
                        )}
                        onChange={handleCustomerChange}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        name="deposit"
                        label="Depósito"
                        value={rental.deposit}
                        onChange={handleInputChange}
                        onFocus={handleFocusDeposit}
                        onBlur={handleBlurDeposit}
                        required={false}
                        variant="standard"
                        type="number"
                        fullWidth
                        InputProps={{
                          startAdornment:
                            focusedDeposit || rental.deposit ? (
                              <InputAdornment
                                position="start"
                                disableTypography={true}
                                sx={{ fontSize: "0.8rem" }}
                              >
                                $
                              </InputAdornment>
                            ) : null,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        name="start_date"
                        label="Fecha de inicio"
                        value={rental.start_date}
                        onChange={handleInputChange}
                        required={true}
                        variant="standard"
                        type="date"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        name="end_date"
                        label="Fecha de fin"
                        value={rental.end_date}
                        onChange={handleInputChange}
                        required={true}
                        variant="standard"
                        type="date"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        name="user_guarantee_name"
                        label="Aval"
                        value={rental.user_guarantee_name}
                        onChange={handleInputChange}
                        variant="standard"
                        type="text"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        name="stored_goods"
                        label="Bienes almacenados"
                        value={rental.stored_goods}
                        onChange={handleInputChange}
                        required={false}
                        variant="standard"
                        type="text"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        name="discount"
                        label="Descuento"
                        value={rental.discount}
                        onChange={handleInputChange}
                        onFocus={handleFocusDiscount}
                        onBlur={handleBlurDiscount}
                        required={false}
                        variant="standard"
                        type="number"
                        fullWidth
                        InputProps={{
                          endAdornment:
                            focusedDiscount || rental.discount ? (
                              <InputAdornment
                                position="end"
                                disableTypography={true}
                                sx={{ fontSize: "0.8rem" }}
                              >
                                %
                              </InputAdornment>
                            ) : null,
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item justifyContent="center" xs={12} my={2}>
                    <MKButton
                      variant="contained"
                      color="primary"
                      type="submit"
                      fullWidth
                    >
                      Crear Contrato
                    </MKButton>
                  </Grid>
                </MKBox>
              </MKBox>
            </Grid>
          </Container>
        </MKBox>
      )}
    </MainLayout>
  );
}

export default NewRentalPage;
