// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import { Link } from "react-router-dom";

function DashboardCard({ title, icon, color, value, link, label }) {
  return (
    <Card
      sx={{
        flex: 1,
        height: "100%",
      }}
    >
      <MKBox display="flex" height="100%">
        <MKBox p={1} m={1} bgColor={color} borderRadius="8" display="flex">
          <MKTypography
            variant="h4"
            color="white"
            display="flex"
            alignSelf="center"
            alignItems="center"
          >
            {typeof icon === "string" ? <Icon>{icon}</Icon> : icon}
          </MKTypography>
        </MKBox>
        <MKBox width="100%" pt={1} pb={2} px={3} lineHeight={1}>
          {link && (
            <MKTypography component={Link} to={link}>
              <MKTypography
                variant="h5"
                textDecoration="underline"
                color="info"
              >
                {title}
              </MKTypography>
            </MKTypography>
          )}
          {!link && <MKTypography variant="h5">{title}</MKTypography>}
          <MKTypography
            variant="body2"
            textTransform="uppercase"
            fontWeight="bold"
            opacity={0.7}
          >
            {value}
          </MKTypography>
          {label && (
            <MKTypography variant="body2" color="secondary">
              {label}
            </MKTypography>
          )}
        </MKBox>
      </MKBox>
    </Card>
  );
}

DashboardCard.defaultProps = {
  link: "",
  label: "",
};

// Typechecking props for the InfoBackgroundCard
DashboardCard.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  color: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  link: PropTypes.string,
  label: PropTypes.string,
};

export default DashboardCard;
