// React imports
import { useCallback, useEffect, useMemo, useState } from "react";

// React Router imports
import { Link, useLocation, useNavigate } from "react-router-dom";

// Layouts
import MainLayout from "layouts/mainLayout";

// Material UI components
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Switch from "@mui/material/Switch";
import DownloadIcon from "@mui/icons-material/Download";
import { Tooltip } from "@mui/material";

// Material Kit 2 PRO React components
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Custom components
import Toolbar from "components/Toolbar";
import ActionsMenu from "components/Menu";
import VideoLoader from "components/Animation/VideoLoader";
import Table from "components/Table";
import customAlert from "components/Alerts/CustomAlert";

// Providers
import customersProvider from "providers/customersProvider";
import { shortDateFormat } from "utils";

function CustomersPage() {
  // Converts URL search parameters to an object with provided suffix.
  const location = useLocation();
  const searchParams = useMemo(() => {
    const params = new URLSearchParams(location.search);
    return Array.from(params.entries()).reduce((acc, [key, value]) => {
      acc[`${key}`] = value;
      return acc;
    }, {});
  }, [location.search]);

  // Grouped state for managing customers and table data
  const [state, setState] = useState({
    customers: [], // List of customers
    rows: [], // Processed rows for the table
    total: 0, // Total number of customers (for pagination)
    page: 0, // Current page for pagination
    query: searchParams, // Query for searching customers
    blockedCustomers: {}, // Dictionary of suspended customers by their IDs
  });

  // Loading state for the table
  const [loading, setLoading] = useState(true);

  // React Router hook for navigation
  const navigate = useNavigate();

  // Memoized columns for the table structure
  const columns = useMemo(
    () => [
      { name: "nombre", align: "left" },
      { name: "email", align: "left" },
      { name: "estatus", align: "left" },
      { name: "bloquear", align: "left" },
      { name: "opciones", align: "left" },
    ],
    []
  );

  // Handles page change event, updating the current page in the state
  const handlePageChange = useCallback((_ev, page) => {
    setState((prevState) => ({ ...prevState, page }));
  }, []);

  // Fetches customer data and updates the state with the list of customers and total count
  const fetchData = useCallback(async () => {
    setLoading(true);
    const {
      data: { data: customersData, total },
    } = await customersProvider.getCustomers(state.page + 1, state.query);
    setState((prevState) => ({
      ...prevState,
      customers: customersData,
      total,
    }));
    setLoading(false);
  }, [state.page, state.query]);

  // Triggers fetchData when page or query changes
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // Toggles the suspension state of a customer in the state
  const handleBlockChange = useCallback(
    async (customerId) => {
      setLoading(true);
      const isBlocked = !state.blockedCustomers[customerId];

      setState((prevState) => ({
        ...prevState,
        blockedCustomers: {
          ...prevState.blockedCustomers,
          [customerId]: isBlocked,
        },
      }));
      const newStatus = isBlocked ? "bloqueado" : "inactivo";
      await customersProvider.updateCustomer(customerId, { status: newStatus });
      fetchData();
    },
    [state.blockedCustomers, fetchData]
  );

  const handleDeleteCustomer = useCallback(
    (customerId) => {
      customAlert({
        title: "¿Estás seguro?",
        text: "Esta acción no se puede deshacer. Se perderá toda la información del cliente.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Sí, eliminar",
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await customersProvider.deleteCustomer(customerId);
            fetchData();
            customAlert({
              title: "Eliminado!",
              text: "El cliente ha sido eliminado.",
              icon: "success",
              confirmButtonColor: "#3085d6",
            });
            navigate("/customers");
          } catch (error) {
            customAlert({
              title: "Error",
              text: "Hubo un problema al eliminar el cliente.",
              icon: "error",
              confirmButtonColor: "#d33",
            });
          }
        }
      });
    },
    [fetchData, navigate]
  );

  // Exportar clientes a excel, usando la query actual
  const exportCustomers = useCallback(async () => {
    try {
      setLoading(true);
      const { data: blobData } = await customersProvider.exportCustomers(
        state.query
      );
      const url = window.URL.createObjectURL(blobData);
      const a = document.createElement("a");
      a.href = url;
      a.download = `clientes-${shortDateFormat(new Date())}.xlsx`;
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.log(error);
      customAlert({
        title: "Error",
        text: "Hubo un problema al exportar los clientes.",
        icon: "error",
      });
    } finally {
      setLoading(false);
    }
  }, [state.query]);

  // Maps the customer data into table rows, updating the "rows" state  whenever the customers or suspendedCustomers state changes.
  // This ensures that the table is always updated with the correct information, including the suspension status.
  useEffect(() => {
    if (state.customers && state.customers.length > 0) {
      setState((prevState) => ({
        ...prevState,
        rows: state.customers.map((customer) => ({
          nombre: (
            <MKButton
              variant="text"
              color="dark"
              sx={{
                padding: 0,
                minWidth: "auto",
                fontSize: "inherit",
                "&:hover": {
                  backgroundColor: "transparent",
                  color: "primary.main",
                },
              }}
              component={Link}
              to={`/customers/${customer.id}`}
            >
              {`${customer.first_name} ${customer.last_name}`}
            </MKButton>
          ),
          email: (
            <MKTypography variant="button" fontWeight="medium">
              {customer.email}
            </MKTypography>
          ),
          estatus: (
            <MKTypography
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {customer.status}
            </MKTypography>
          ),
          bloquear: (
            <Switch
              checked={
                customer.status === "bloqueado" ||
                state.blockedCustomers[customer.id] ||
                false
              }
              onChange={() => handleBlockChange(customer.id)}
            />
          ),
          opciones: (
            <Box display="flex" gap={1}>
              <ActionsMenu
                actions={[
                  {
                    icon: <EditIcon />,
                    text: "Editar",
                    onClick: () => navigate(`/customers/${customer.id}/edit`),
                  },
                  {
                    icon: <DeleteIcon />,
                    text: "Eliminar",
                    onClick: () => handleDeleteCustomer(customer.id),
                  },
                ]}
                disabled={
                  customer.status === "bloqueado" ||
                  state.blockedCustomers[customer.id] ||
                  false
                }
              />
            </Box>
          ),
        })),
      }));
    } else {
      setState((prevState) => ({ ...prevState, rows: [] }));
    }
  }, [
    state.customers,
    navigate,
    fetchData,
    handleDeleteCustomer,
    handleBlockChange,
    state.blockedCustomers,
  ]);

  return (
    <MainLayout title="Clientes">
      <Toolbar
        setQuery={(query) => setState((prevState) => ({ ...prevState, query }))}
        ransackQuery="first_name_or_last_name_or_email_or_status_cont"
        newItemButton={
          <Box display="flex" gap={2}>
            <MKButton
              variant="contained"
              size="small"
              color="primary"
              component={Link}
              to="/customers/new"
            >
              + Agregar Cliente
            </MKButton>
            <Tooltip title="Exportar a Excel">
              <MKButton iconOnly onClick={exportCustomers}>
                <DownloadIcon />
              </MKButton>
            </Tooltip>
          </Box>
        }
      />

      <Grid container item xs={12} lg={12}>
        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="15vh"
          >
            <VideoLoader />
          </Box>
        ) : state.rows.length === 0 ? (
          <MKTypography variant="h6" fontWeight="medium">
            No se encontraron clientes
          </MKTypography>
        ) : (
          <Table
            columns={columns}
            rows={state.rows}
            total={state.total}
            page={state.page}
            handlePageChange={handlePageChange}
          />
        )}
      </Grid>
    </MainLayout>
  );
}

export default CustomersPage;
