// Layouts
import MainLayout from "layouts/mainLayout";

import TabIndex from "pages/Payments/tabIndex";

function PaymentsPage({ defaultTab = 0, query }) {
  return (
    <MainLayout title="Pagos">
      <TabIndex defaultTab={defaultTab} query={query} />
    </MainLayout>
  );
}

export default PaymentsPage;
