// React imports
import { useCallback, useContext, useEffect, useMemo, useState } from "react";

// React Router imports
import { useLocation, useNavigate } from "react-router-dom";

// Layouts
import MainLayout from "layouts/mainLayout";

// Material UI components
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import DeleteIcon from "@mui/icons-material/Delete";
import Switch from "@mui/material/Switch";
import EditIcon from "@mui/icons-material/Edit";

// Material Kit 2 PRO React components
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import MKBox from "components/MKBox";

// Custom components
import Toolbar from "components/Toolbar";
import ActionsMenu from "components/Menu";
import VideoLoader from "components/Animation/VideoLoader";
import customAlert from "components/Alerts/CustomAlert";

import Table from "components/Table";

// Providers
import subsidiaryProvider from "providers/subsidiaryProvider";
import SessionContext from "contexts/SessionContext";

function SubsidiariesPage() {
  // Converts URL search parameters to an object with "_cont" suffix.
  const location = useLocation();
  const searchParams = useMemo(() => {
    const params = new URLSearchParams(location.search);
    return Array.from(params.entries()).reduce((acc, [key, value]) => {
      acc[`${key}_cont`] = value;
      return acc;
    }, {});
  }, [location.search]);

  const { currentUser } = useContext(SessionContext);

  // Grouped state for managing units and table data
  const [state, setState] = useState({
    subsidiaries: [], // List of subsidiaries
    rows: [], // Processed rows for the table
    total: 0, // Total number of subsidiaries (for pagination)
    page: 0, // Current page for pagination
    query: searchParams, // Query for searching subsidiaries
    blockedSubsidiaries: {}, // Dictionary of blocked subsidiaries by their IDs
  });

  // Loading state for the table
  const [loading, setLoading] = useState(true);

  // React Router hook for navigation
  const navigate = useNavigate();

  // Memoized columns for the table structure
  const columns = useMemo(
    () => [
      { name: "nombre", align: "left" },
      { name: "folios", align: "center" },
      { name: "estatus", align: "center" },
      { name: "bloquear", align: "center" },
      { name: "opciones", align: "center" },
    ],
    []
  );

  // Handles page change event, updating the current page in the state
  const handlePageChange = useCallback((_ev, newPage) => {
    setState((prevState) => ({ ...prevState, page: newPage }));
  }, []);

  // Fetches unit data and updates the state with the list of units and total count
  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const {
        data: { data: subsidiariesData, total },
      } = await subsidiaryProvider.getSubsidiaries(state.page + 1, state.query);
      setState((prevState) => ({
        ...prevState,
        subsidiaries: subsidiariesData || [],
        total: total || 0,
      }));
    } catch (error) {
      console.error("Error fetching subsidiaries:", error);
    } finally {
      setLoading(false);
    }
  }, [state.page, state.query]);

  // Triggers fetchData when page or query changes
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // Toggles the suspension state of a unit in the state
  const handleBlockChange = useCallback(
    async (subsidiaryId) => {
      const isBlocked = !state.blockedSubsidiaries[subsidiaryId];

      setState((prevState) => ({
        ...prevState,
        blockedSubsidiaries: {
          ...prevState.blockedSubsidiaries,
          [subsidiaryId]: isBlocked,
        },
      }));
      const newStatus = isBlocked ? "bloqueada" : "activa";
      await subsidiaryProvider.updateSubsidiary(subsidiaryId, {
        status: newStatus,
      });
      fetchData();
    },
    [state.blockedSubsidiaries, fetchData]
  );

  const handleFoliosUpdate = useCallback(
    async (subsidiaryId, currentValue) => {
      customAlert({
        title: "Actualizar folios",
        input: "number",
        inputValue: currentValue,
        inputAttributes: {
          min: 0,
          step: 1,
        },
        showCancelButton: true,
        confirmButtonText: "Actualizar",
        cancelButtonText: "Cancelar",
        inputValidator: (value) => {
          if (!value) {
            return "Debes ingresar una cantidad de folios";
          }
          if (value < 0) {
            return "La cantidad de folios no puede ser negativa";
          }
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await subsidiaryProvider.updateSubsidiary(subsidiaryId, {
              folios: result.value,
            });
            fetchData();
            customAlert({
              title: "Folios actualizados",
              text: "",
              icon: "success",
            });
          } catch (error) {
            customAlert({
              tike: "error",
              text: "Ocurrió un error al actualizar los folios",
              icon: "error",
            });
          }
        }
      });
    },
    [fetchData]
  );

  // Deletes a unit and refreshes the unit list
  const handleDeleteSubsidiary = useCallback(
    (subsidiaryId) => {
      customAlert({
        title: "¿Estás seguro?",
        text: "Esta acción no se puede deshacer.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sí, eliminar",
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await subsidiaryProvider.deleteSubsidiary(subsidiaryId);
            fetchData();
            customAlert({
              title: "Eliminado!",
              text: "La sucursal ha sido eliminada.",
              icon: "success",
            });
            navigate("/subsidiaries");
          } catch (error) {
            customAlert({
              title: "Error",
              text: "Ocurrió un error al intentar eliminar la sucursal.",
              icon: "error",
            });
          }
        }
      });
    },
    [fetchData, navigate]
  );

  // Maps the unit data into table rows, updating the "rows" state whenever the units or suspendedUnits state changes.
  // This ensures that the table is always updated with the correct information, including the suspension status.
  useEffect(() => {
    if (state.subsidiaries && state.subsidiaries.length > 0) {
      setState((prevState) => ({
        ...prevState,
        rows: state.subsidiaries.map((subsidiary) => ({
          nombre: (
            <MKTypography variant="button" fontWeight="medium">
              {subsidiary.name}
            </MKTypography>
          ),
          estatus: (
            <MKTypography
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {subsidiary.status}
            </MKTypography>
          ),
          folios: (
            <MKBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              gap={1}
            >
              <MKTypography variant="button" fontWeight="medium">
                {subsidiary.folios}
              </MKTypography>
              <MKButton
                variant="outlined"
                size="small"
                iconOnly={true}
                onClick={() =>
                  handleFoliosUpdate(subsidiary.id, subsidiary.folios)
                }
              >
                <EditIcon />
              </MKButton>
            </MKBox>
          ),
          bloquear: (
            <Switch
              checked={
                subsidiary.status === "bloqueada" ||
                state.blockedSubsidiaries[subsidiary.id] ||
                false
              }
              onChange={() => handleBlockChange(subsidiary.id)}
            />
          ),
          opciones: (
            <Box display="flex" gap={1}>
              <ActionsMenu
                actions={[
                  {
                    icon: <DeleteIcon />,
                    text: "Eliminar",
                    onClick: () => handleDeleteSubsidiary(subsidiary.id),
                    disabled:
                      currentUser.subsidiary &&
                      currentUser.subsidiary.id === subsidiary.id,
                  },
                ]}
                disabled={
                  subsidiary.status === "bloqueada" ||
                  state.blockedSubsidiaries[subsidiary.id] ||
                  false
                }
              />
            </Box>
          ),
        })),
      }));
    } else {
      setState((prevState) => ({ ...prevState, rows: [] }));
    }
  }, [
    state.subsidiaries,
    state.blockedSubsidiaries,
    navigate,
    fetchData,
    handleDeleteSubsidiary,
    handleBlockChange,
    handleFoliosUpdate,
    currentUser,
  ]);

  return (
    <>
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <VideoLoader />
        </Box>
      ) : (
        <MainLayout title="Sucursales">
          <Toolbar
            setQuery={(query) =>
              setState((prevState) => ({
                ...prevState,
                query,
              }))
            }
            ransackQuery="name_or_status_cont"
          />
          <Grid item xs={12} lg={12}>
            {state.rows.length === 0 ? (
              <MKTypography variant="h6" fontWeight="medium">
                No se encontraron sucursales
              </MKTypography>
            ) : (
              <Table
                columns={columns}
                rows={state.rows}
                total={state.total}
                page={state.page}
                handlePageChange={handlePageChange}
              />
            )}
          </Grid>
        </MainLayout>
      )}
    </>
  );
}

export default SubsidiariesPage;
