// React imports
import { useState } from "react";
import { useNavigate } from "react-router-dom";

// Layouts
import MainLayout from "layouts/mainLayout";

// Material UI components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKAlert from "components/MKAlert";

import productsProvider from "providers/productsProvider";

function NewProductPage() {
  // React Router hook for navigation
  const navigate = useNavigate();

  // State for form errors
  const [formErrors, setFormErrors] = useState("");

  const [focusedPrice, setFocusedPrice] = useState(false);

  const handleFocusPrice = () => setFocusedPrice(true);
  const handleBlurPrice = () => setFocusedPrice(false);

  // State for product data
  const [product, setProduct] = useState({
    name: "",
    description: "",
    price: "",
    stock: "",
  });

  // Handles changes in form input fields and updates product state
  const handleInputChange = ({ target: { name, value } }) => {
    if (name === "price" || name === "stock") {
      let validValue = value.replace(/[^0-9.]/g, "");

      if (validValue === "" || validValue >= 0) {
        setProduct((prevProduct) => ({
          ...prevProduct,
          [name]: validValue,
        }));
      }
    } else {
      setProduct((prevProduct) => ({
        ...prevProduct,
        [name]: value,
      }));
    }
  };

  const submitForm = async (event) => {
    event.preventDefault();
    try {
      await productsProvider.createProduct(product);
      navigate("/products", {
        state: { feedback: "Producto creado correctamente" },
      });
    } catch (error) {
      let errorMsg = "Error en el servidor, favor de reportar el error";
      if (error.response && error.response.data) {
        errorMsg = error.response.data.join(", ");
      }
      setFormErrors(errorMsg);
    }
  };

  return (
    <MainLayout title="Nuevo Producto">
      <MKBox component="section" py={12}>
        <Container>
          <Grid container item xs={12} lg={7} sx={{ mx: "auto" }}>
            <MKBox width="100%" component="form" onSubmit={submitForm}>
              {formErrors && (
                <MKAlert color="error" dismissible={true}>
                  {formErrors}
                </MKAlert>
              )}
              <MKBox p={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <MKInput
                      name="name"
                      label="Nombre"
                      value={product.name}
                      onChange={handleInputChange}
                      required={true}
                      variant="standard"
                      type="text"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <MKInput
                      name="description"
                      label="Descripción"
                      value={product.description}
                      onChange={handleInputChange}
                      required={false}
                      variant="standard"
                      type="text"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MKInput
                      name="price"
                      label="Precio"
                      value={product.price}
                      onChange={handleInputChange}
                      onFocus={handleFocusPrice}
                      onBlur={handleBlurPrice}
                      required={true}
                      variant="standard"
                      type="number"
                      fullWidth
                      InputProps={{
                        startAdornment:
                          focusedPrice || product.price ? (
                            <InputAdornment
                              position="start"
                              disableTypography={true}
                              sx={{ fontSize: "0.8rem" }}
                            >
                              $
                            </InputAdornment>
                          ) : null,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MKInput
                      name="stock"
                      label="Stock"
                      value={product.stock}
                      onChange={handleInputChange}
                      required={true}
                      variant="standard"
                      type="number"
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Grid container item justifyContent="center" xs={12} my={2}>
                  <MKButton
                    variant="contained"
                    color="primary"
                    type="submit"
                    fullWidth
                  >
                    Crear Producto
                  </MKButton>
                </Grid>
              </MKBox>
            </MKBox>
          </Grid>
        </Container>
      </MKBox>
    </MainLayout>
  );
}

export default NewProductPage;
