// React imports
import { useCallback, useMemo, useState } from "react";

// External libraries
import { Link, useLocation } from "react-router-dom";

// Layouts
import MainLayout from "layouts/mainLayout";

// Material Kit 2 PRO React components
import MKButton from "components/MKButton";
import Toolbar from "components/Toolbar";

// Providers
import invoicesProvider from "providers/invoicesProvider";
import InvoicesTable from "pages/Invoices/table";

function InvoicesPage() {
  // Loading state for the table
  const [loading, setLoading] = useState(true);

  // Converts URL search parameters to an object with "_cont" suffix.
  const location = useLocation();
  const searchParams = useMemo(() => {
    const params = new URLSearchParams(location.search);
    return Array.from(params.entries()).reduce((acc, [key, value]) => {
      acc[`${key}_cont`] = value;
      return acc;
    }, {});
  }, [location.search]);

  // Query state for searching rentals
  const [query, setQuery] = useState(searchParams);

  const fetchData = useCallback(
    async function (page) {
      setLoading(true);
      const {
        data: { data: invoicesData, total },
      } = await invoicesProvider.getInvoices(page + 1, query);
      setLoading(false);
      return { invoices: invoicesData, total };
    },
    [query]
  );

  return (
    <MainLayout title="Cobros">
      <Toolbar
        setQuery={setQuery}
        ransackQuery="customer_first_name_or_customer_last_name_or_unit_name_or_status_cont"
        newItemButton={
          <MKButton
            color="primary"
            size="small"
            variant="contained"
            component={Link}
            to="/invoices/new"
          >
            + Agregar Cobro
          </MKButton>
        }
      />

      <InvoicesTable fetchData={fetchData} loading={loading} />
    </MainLayout>
  );
}

export default InvoicesPage;
