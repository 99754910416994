import React, { useContext, useRef } from "react";
import { styled, alpha } from "@mui/material/styles";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Box from "@mui/material/Box";
import MKButton from "components/MKButton"; // Importa tu botón personalizado
import SessionContext from "contexts/SessionContext";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.text.primary,
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export default function ActionsMenu({ actions = [], disabled }) {
  const { currentUser } = useContext(SessionContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  // Referencia para el botón
  const buttonRef = useRef(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    // Hacer que el botón pierda el foco para evitar que quede "seleccionado"
    if (buttonRef.current) {
      buttonRef.current.blur();
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <MKButton
        ref={buttonRef}
        color="primary"
        variant="outlined"
        size="small"
        onClick={handleClick}
        endIcon={
          <KeyboardArrowDownIcon
            sx={{
              transition: "transform 0.3s ease",
              transform: open ? "rotate(180deg)" : "rotate(0deg)",
            }}
          />
        }
        disabled={disabled}
      >
        Acciones
      </MKButton>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {actions.map(
          ({ icon, text, onClick, requiredRoles = [], ...rest }, index) =>
            (requiredRoles.length === 0 ||
              requiredRoles.includes(currentUser.role_id)) && (
              <MenuItem
                key={index}
                onClick={() => {
                  handleClose();
                  onClick();
                }}
                disableRipple
                {...rest}
              >
                {icon}
                {text}
              </MenuItem>
            )
        )}
      </StyledMenu>
    </Box>
  );
}
