// VideoLoader.js
import React from "react";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  loaderContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100vh",
    position: "fixed",
    top: 0,
    left: 0,
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    zIndex: 1300,
  },
  video: {
    width: "100px",
    height: "100px",
    borderRadius: "50%",
  },
}));

const VideoLoader = () => {
  const classes = useStyles();

  return (
    <div className={classes.loaderContainer}>
      <video
        className={classes.video}
        src="/Animation.mp4"
        autoPlay
        loop
        muted
      />
    </div>
  );
};

export default VideoLoader;
