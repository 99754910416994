// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Collapse from "@mui/material/Collapse";
import { Divider } from "@mui/material";
import { Logout } from "@mui/icons-material";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import NavbarItem from "layouts/Navbar/NavbarItem";
import MKButton from "components/MKButton";
import { useTheme } from "@mui/material/styles";

function NavbarMobile({ routes, open, signOut }) {
  const theme = useTheme();
  const renderNavbarItems = routes.map(
    ({ name, icon, route, showInNavbar, mobileName }, index) =>
      showInNavbar && (
        <NavbarItem
          key={`${name}-${index}`}
          name={name}
          icon={icon}
          route={route}
          mobileName={mobileName}
          isMobile={true}
        />
      )
  );

  return (
    <Collapse in={Boolean(open)} timeout="auto" unmountOnExit>
      <MKBox width="calc(100% + 1.625rem)" my={2} ml={-2}>
        {renderNavbarItems}
        <Divider />
        <MKBox
          mx={1}
          p={1}
          display="flex"
          alignItems="baseline"
          borderRadius="md"
          color="dark"
          opacity={0.6}
          sx={{
            cursor: "pointer",
            userSelect: "none",
            "&:hover": {
              backgroundColor: theme.palette.secondary.main,
            },
          }}
        >
          <MKTypography
            variant="body2"
            lineHeight={1}
            color="inherit"
            sx={{ alignSelf: "center", "& *": { verticalAlign: "middle" } }}
          >
            <Logout />
          </MKTypography>
          <MKButton variant="text" color="dark" onClick={signOut}>
            <MKTypography
              variant="button"
              fontWeight="regular"
              textTransform="capitalize"
              color="inherit"
              sx={{
                ml: 1,
                mr: 0.25,
              }}
            >
              Cerrar sesión
            </MKTypography>
          </MKButton>
        </MKBox>
      </MKBox>
    </Collapse>
  );
}

// Typechecking props for the NavbarMobile
NavbarMobile.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])).isRequired,
  open: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
};

export default NavbarMobile;
