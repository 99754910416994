import { useState, useEffect, useContext, useCallback } from "react";

// react-router components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";

import { Container, Icon } from "@mui/material";

// Material Kit 2 PRO React examples
import NavbarItem from "layouts/Navbar/NavbarItem";
import NavbarMobile from "layouts/Navbar/NavbarMobile";

// Material Kit 2 PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";

import ugLogo from "assets/images/ug-logo.png";

import SessionContext from "contexts/SessionContext";

import {
  adminRoutes,
  managerRoutes,
  customerRoutes,
  guestRoutes,
} from "routes";

function DefaultNavbar({ transparent, light, sticky, relative, center }) {
  const [mobileNavbar, setMobileNavbar] = useState(false);
  const [mobileView, setMobileView] = useState(false);

  const openMobileNavbar = () => setMobileNavbar(!mobileNavbar);

  useEffect(() => {
    // A function that sets the display state for the NavbarMobile.
    function displayMobileNavbar() {
      if (window.innerWidth < breakpoints.values.lg) {
        setMobileView(true);
        setMobileNavbar(false);
      } else {
        setMobileView(false);
        setMobileNavbar(false);
      }
    }

    /**
     The event listener that's calling the displayMobileNavbar function when
     resizing the window.
    */
    window.addEventListener("resize", displayMobileNavbar);

    // Call the displayMobileNavbar function to set the state with the initial value.
    displayMobileNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", displayMobileNavbar);
  }, []);

  const { currentUser, setCurrentUser } = useContext(SessionContext);
  const [routes, setRoutes] = useState([...guestRoutes]);
  const signOut = () => {
    setCurrentUser({});
    sessionStorage.removeItem("currentUser");
    localStorage.removeItem("currentUser");
    window.location.href = "/sign-in";
  };

  useEffect(() => {
    if (currentUser && currentUser.id) {
      setRoutes([...guestRoutes, ...customerRoutes]);

      if (currentUser.role_id === 2 || currentUser.role_id === 1) {
        setRoutes([...guestRoutes, ...customerRoutes, ...managerRoutes]);
      }

      if (currentUser.role_id === 1) {
        setRoutes([
          ...guestRoutes,
          ...customerRoutes,
          ...managerRoutes,
          ...adminRoutes,
        ]);
      }
    }
  }, [currentUser]);

  const renderNavbarItems = useCallback(() => {
    if (routes) {
      return routes.map(({ name, icon, route, light, showInNavbar }, index) => {
        if (!showInNavbar) return null;

        return (
          <NavbarItem
            key={`${name}-${index}`}
            name={name}
            icon={icon}
            route={route}
            light={light}
          />
        );
      });
    }
  }, [routes]);

  return (
    <Container sx={sticky ? { position: "sticky", top: 0, zIndex: 10 } : null}>
      <MKBox
        py={1}
        px={{ xs: 4, sm: transparent ? 2 : 3, lg: transparent ? 0 : 2 }}
        my={relative ? 0 : 2}
        mx={relative ? 0 : 3}
        width={relative ? "100%" : "calc(100% - 48px)"}
        borderRadius="xl"
        shadow={transparent ? "none" : "md"}
        color={light ? "white" : "dark"}
        position={relative ? "relative" : "absolute"}
        left={0}
        zIndex={3}
        sx={({
          palette: { transparent: transparentColor, white },
          functions: { rgba },
        }) => ({
          backgroundColor: transparent
            ? transparentColor.main
            : rgba(white.main, 0.8),
          backdropFilter: transparent ? "none" : `saturate(200%) blur(30px)`,
        })}
      >
        <MKBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <MKBox
            component={Link}
            to="/"
            lineHeight={1}
            py={transparent ? 1.5 : 0.75}
            pl={relative || transparent ? 0 : { xs: 0, lg: 1 }}
          >
            <MKBox
              component="img"
              src={ugLogo}
              alt="logo"
              width="150px"
              opacity={0.9}
            />
          </MKBox>
          <MKBox
            color="inherit"
            display={{ xs: "none", lg: "flex" }}
            ml="auto"
            mr={center ? "auto" : 0}
          >
            {renderNavbarItems()}
          </MKBox>

          {!mobileView && currentUser && currentUser.id && (
            <MKButton
              variant="contained"
              color="primary"
              size="small"
              onClick={signOut}
            >
              Cerrar sesión
            </MKButton>
          )}

          {mobileView && currentUser && currentUser.id && (
            <MKBox
              display={{ xs: "inline-block", lg: "none" }}
              lineHeight={0}
              py={1.5}
              pl={1.5}
              color={transparent ? "primary" : "inherit"}
              sx={{ cursor: "pointer" }}
              onClick={openMobileNavbar}
            >
              <Icon fontSize="default">{mobileNavbar ? "close" : "menu"}</Icon>
            </MKBox>
          )}
        </MKBox>

        {mobileView && currentUser && currentUser.id && (
          <MKBox
            bgColor={transparent ? "white" : "transparent"}
            shadow={transparent ? "lg" : "none"}
            borderRadius="xl"
            px={transparent ? 2 : 0}
            position="absolute"
            width="100%"
          >
            <NavbarMobile
              routes={routes}
              open={mobileNavbar}
              signOut={signOut}
            />
          </MKBox>
        )}
      </MKBox>
    </Container>
  );
}

// Typechecking props for the DefaultNavbar
DefaultNavbar.propTypes = {
  transparent: PropTypes.bool,
  light: PropTypes.bool,
  sticky: PropTypes.bool,
  relative: PropTypes.bool,
  center: PropTypes.bool,
};

export default DefaultNavbar;
