import MKBox from "components/MKBox";
import TabIndex from "pages/Payments/tabIndex";

function CustomerPaymentsTab({ customer }) {
  const query = {
    customer_id_eq: customer.id,
  };

  return (
    <MKBox>
      <TabIndex defaultTab={0} query={query} />
    </MKBox>
  );
}

export default CustomerPaymentsTab;
