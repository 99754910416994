import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKButton from "components/MKButton";
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";

function Toolbar({ setQuery, children, ransackQuery, newItemButton }) {
  const [tempQuery, setTempQuery] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setQuery(tempQuery);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTempQuery({
      ...tempQuery,
      [name]: value,
    });
  };

  return (
    <>
      <Grid item xs={12}>
        <MKBox
          component="form"
          role="form"
          onSubmit={handleSubmit}
          display="flex"
          justifyContent="flex-end"
          gap="1rem"
        >
          {children}
          <MKBox>
            <MKInput
              type="text"
              label="Búsqueda"
              name={ransackQuery}
              fullWidth
              onChange={handleInputChange}
            />
          </MKBox>
          <MKBox>
            <MKButton type="submit" variant="gradient" color="info" fullWidth>
              Buscar
            </MKButton>
          </MKBox>
        </MKBox>
      </Grid>
      <Grid item xs={12}>
        {newItemButton}
      </Grid>
    </>
  );
}

export default Toolbar;
