// React imports
import { useEffect, useState } from "react";

// External libraries
import { useNavigate, useParams } from "react-router-dom";

// Layouts
import MainLayout from "layouts/mainLayout";

// Material UI components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKAlert from "components/MKAlert";

// Providers
import unitsProvider from "providers/unitsProvider";
import customersProvider from "providers/customersProvider";
import rentalsProvider from "providers/rentalsProvider";
import customAlert from "components/Alerts/CustomAlert";
import { InputAdornment } from "@mui/material";

//Custom components
import VideoLoader from "components/Animation/VideoLoader";

function EditRentalPage() {
  // Get parameters from the URL
  const { id } = useParams();

  // React Router hook for navigation
  const navigate = useNavigate();

  // Loading state for the form
  const [loading, setLoading] = useState(true);
  const [focusedDeposit, setFocusedDeposit] = useState(false);

  const handleFocusDeposit = () => setFocusedDeposit(true);
  const handleBlurDeposit = () => setFocusedDeposit(false);

  // State for managing form errors
  const [formErrors, setFormErrors] = useState("");

  // State for rental data
  const [rental, setRental] = useState({
    unit_id: "",
    user_id: "",
    deposit: "",
    start_date: "",
    end_date: "",
    user_guarantee_name: "",
    stored_goods: "",
  });

  const [details, setDetails] = useState({
    unitName: "",
    customerName: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const { data } = await rentalsProvider.getRental(id);
        setRental(data);

        const [unitResponse, customerResponse] = await Promise.all([
          unitsProvider.getUnit(data.unit_id),
          customersProvider.getCustomer(data.user_id),
        ]);

        setDetails({
          unitName: `${unitResponse.data.floor ?? ""}${
            unitResponse.data.block ?? ""
          } ${unitResponse.data.name}`,
          customerName: `${customerResponse.data.first_name} ${customerResponse.data.last_name}`,
        });
      } catch (error) {
        console.error("Error fetching customer or invoice data", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [id]);

  // Handles changes in the form input fields and updates rental state
  const handleInputChange = ({ target: { name, value } }) => {
    setRental((prevRental) => ({
      ...prevRental,
      [name]: name === "deposit" ? Math.max(0, Number(value) || 0) : value,
    }));
  };

  // Submits the form after validating the data and handling errors
  const submitForm = async (event) => {
    event.preventDefault();

    try {
      customAlert({
        title: "Aviso de actualización de cobros",
        text: "Los cobros no se actualizarán automáticamente, favor de actualizarlos manualmente en caso de ser necesario.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Editar contrato",
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await rentalsProvider.updateRental(id, rental);
            customAlert({
              title: "Contrato actualizado!",
              text: "Favor de actualizar los cobros manualmente.",
              icon: "success",
            });
            navigate("/rentals", {
              state: { feedback: "Contrato actualizado correctamente" },
            });
          } catch (error) {
            customAlert({
              title: "Error",
              text: "Hubo un problema al editar el contrato.",
              icon: "error",
            });
          }
        }
      });
    } catch (error) {
      let errorMsg = "Error en el servidor, favor de reportar el error";
      if (error.response && error.response.data) {
        errorMsg = error.response.data.join(", ");
      }
      setFormErrors(errorMsg);
    }
  };

  return (
    <MainLayout title="Editar Contrato">
      {loading ? (
        <MKBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="50vh"
        >
          <VideoLoader />
        </MKBox>
      ) : (
        <MKBox component="section" py={12}>
          <Container>
            <Grid container item xs={12} lg={7} sx={{ mx: "auto" }}>
              <MKBox width="100%" component="form" onSubmit={submitForm}>
                {formErrors && (
                  <MKAlert color="error" dismissible={true}>
                    {formErrors}
                  </MKAlert>
                )}
                <MKBox p={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        label="Unidad"
                        value={details.unitName}
                        variant="standard"
                        type="text"
                        fullWidth
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        label="Cliente"
                        value={details.customerName}
                        variant="standard"
                        type="text"
                        fullWidth
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        name="deposit"
                        label="Depósito"
                        value={rental.deposit}
                        onChange={handleInputChange}
                        onFocus={handleFocusDeposit}
                        onBlur={handleBlurDeposit}
                        required={false}
                        variant="standard"
                        type="number"
                        fullWidth
                        InputProps={{
                          startAdornment:
                            focusedDeposit || rental.deposit ? (
                              <InputAdornment
                                position="start"
                                disableTypography={true}
                                sx={{ fontSize: "0.8rem" }}
                              >
                                $
                              </InputAdornment>
                            ) : null,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        name="start_date"
                        label="Fecha de inicio"
                        value={rental.start_date}
                        onChange={handleInputChange}
                        required={true}
                        variant="standard"
                        type="date"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        name="end_date"
                        label="Fecha de fin"
                        value={rental.end_date}
                        onChange={handleInputChange}
                        required={true}
                        variant="standard"
                        type="date"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        name="user_guarantee_name"
                        label="Aval"
                        value={rental.user_guarantee_name}
                        onChange={handleInputChange}
                        variant="standard"
                        type="text"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKInput
                        name="stored_goods"
                        label="Bienes almacenados"
                        value={rental.stored_goods}
                        onChange={handleInputChange}
                        variant="standard"
                        type="text"
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                  <Grid container item justifyContent="center" xs={12} my={2}>
                    <MKButton
                      variant="contained"
                      color="primary"
                      type="submit"
                      fullWidth
                    >
                      Guardar Cambios
                    </MKButton>
                  </Grid>
                </MKBox>
              </MKBox>
            </Grid>
          </Container>
        </MKBox>
      )}
    </MainLayout>
  );
}

export default EditRentalPage;
