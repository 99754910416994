// React imports
import { useContext, useState } from "react";

// External libraries
import axios from "axios";
import { useNavigate } from "react-router-dom";

// Layouts
import MainLayout from "layouts/mainLayout";

// Material UI components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKAlert from "components/MKAlert";

// Providers
import customersProvider from "providers/customersProvider";

// Contexts
import SessionContext from "contexts/SessionContext";

function NewCustomerPage() {
  // Get current user from session context
  const { currentUser } = useContext(SessionContext);

  // React Router hook for navigation
  const navigate = useNavigate();

  // State for form errors
  const [formErrors, setFormErrors] = useState("");
  const [zipCodeError, setZipCodeError] = useState("");

  // State for customer data
  const [customer, setCustomer] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    zip_code: "",
    street: "",
    exterior_number: "",
    interior_number: "",
    neighborhood: "",
    city: "",
    state: "",
    country: "",
    status: "inactivo",
    password: "ustedguarda123",
    password_confirmation: "ustedguarda123",
    subsidiary_id: currentUser.subsidiary.id,
    fiscal_number: "",
    fiscal_name: "",
  });

  // Handle input changes in the form
  const handleInputChange = ({ target: { name, value } }) => {
    let newValue = name === "fiscal_number" ? value.toUpperCase() : value;

    setCustomer((prevCustomer) => ({
      ...prevCustomer,
      [name]: newValue,
    }));
  };

  // Handle changes for the zip code, fetch location data if valid
  const handleZipCodeChange = async (e) => {
    const zipCode = e.target.value;
    setCustomer((prevState) => ({ ...prevState, zip_code: zipCode }));

    if (zipCode.length === 5) {
      try {
        const { data } = await axios.get(
          `https://api.zippopotam.us/mx/${zipCode}`
        );
        const { places, country } = data;
        const neighborhood = places[0]["place name"];
        const state = places[0]["state"];

        setCustomer((prevState) => ({
          ...prevState,
          neighborhood,
          state,
          country,
        }));
      } catch (error) {
        console.error("Error fetching location data:", error);
      } finally {
        setZipCodeError("");
      }
    } else {
      setZipCodeError("El código postal debe tener 5 dígitos.");
    }
  };

  // Submits the form after validating the data and handling errors
  const submitForm = async (event) => {
    event.preventDefault();

    if (zipCodeError) {
      setFormErrors("Por favor, corrige los errores antes de continuar");
      return;
    }

    try {
      await customersProvider.createCustomer(customer);
      navigate("/customers", {
        state: { feedback: "Cliente creado correctamente" },
      });
    } catch (error) {
      let errorMsg = "Error en el servidor, favor de reportar el error";
      if (error.response && error.response.data) {
        errorMsg = error.response.data.join(", ");
      }
      setFormErrors(errorMsg);
    }
  };

  return (
    <MainLayout title="Nuevo Cliente">
      <MKBox component="section" py={12}>
        <Container>
          <Grid container item xs={12} lg={7} sx={{ mx: "auto" }}>
            <MKBox width="100%" component="form" onSubmit={submitForm}>
              {formErrors && (
                <MKAlert color="error" dismissible={true}>
                  {formErrors}
                </MKAlert>
              )}
              <MKBox p={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <MKInput
                      name="first_name" //name of the field
                      label="Nombre" //description of the field
                      value={customer.first_name} //value of the field
                      onChange={handleInputChange} //function to handle the change
                      required={true} //required field
                      variant="standard" //variant of the input
                      type="text" //type of the input
                      fullWidth //additional layout options
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MKInput
                      name="last_name"
                      label="Apellidos"
                      value={customer.last_name}
                      onChange={handleInputChange}
                      required={true}
                      variant="standard"
                      type="text"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MKInput
                      name="email"
                      label="Email"
                      value={customer.email}
                      onChange={handleInputChange}
                      required={true}
                      variant="standard"
                      type="email"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MKInput
                      name="phone"
                      label="Teléfono"
                      value={customer.phone}
                      onChange={handleInputChange}
                      variant="standard"
                      required={true}
                      type="tel"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MKInput
                      name="fiscal_number"
                      label="RFC"
                      value={customer.fiscal_number}
                      onChange={handleInputChange}
                      variant="standard"
                      required={true}
                      type="text"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MKInput
                      name="fiscal_name"
                      label="Razón Social"
                      value={customer.fiscal_name}
                      onChange={handleInputChange}
                      variant="standard"
                      required={true}
                      type="text"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MKInput
                      name="zip_code"
                      label="Código Postal"
                      value={customer.zip_code}
                      onChange={handleZipCodeChange}
                      variant="standard"
                      required={true}
                      type="text"
                      fullWidth
                      error={!!zipCodeError}
                      helperText={zipCodeError}
                      inputProps={{
                        maxLength: 5,
                        pattern: "[0-9]{5}",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MKInput
                      name="street"
                      label="Calle"
                      value={customer.street}
                      onChange={handleInputChange}
                      variant="standard"
                      required={true}
                      type="text"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MKInput
                      name="exterior_number"
                      label="Número exterior"
                      value={customer.exterior_number}
                      onChange={handleInputChange}
                      variant="standard"
                      required={true}
                      type="text"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MKInput
                      name="interior_number"
                      label="Número interior"
                      value={customer.interior_number}
                      onChange={handleInputChange}
                      variant="standard"
                      type="text"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MKInput
                      name="neighborhood"
                      label="Colonia"
                      value={customer.neighborhood}
                      onChange={(e) =>
                        setCustomer({
                          ...customer,
                          neighborhood: e.target.value,
                        })
                      }
                      variant="standard"
                      required={true}
                      type="text"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MKInput
                      name="city"
                      label="Ciudad"
                      value={customer.city}
                      onChange={handleInputChange}
                      variant="standard"
                      required={true}
                      type="text"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MKInput
                      name="state"
                      label="Estado"
                      value={customer.state}
                      onChange={(e) =>
                        setCustomer({ ...customer, state: e.target.value })
                      }
                      variant="standard"
                      required={true}
                      type="text"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MKInput
                      name="country"
                      label="País"
                      value={customer.country}
                      onChange={(e) =>
                        setCustomer({ ...customer, country: e.target.value })
                      }
                      variant="standard"
                      required={true}
                      type="text"
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Grid container item justifyContent="center" xs={12} my={2}>
                  <MKButton
                    variant="contained"
                    color="primary"
                    type="submit"
                    fullWidth
                  >
                    Crear cliente
                  </MKButton>
                </Grid>
              </MKBox>
            </MKBox>
          </Grid>
        </Container>
      </MKBox>
    </MainLayout>
  );
}

export default NewCustomerPage;
