import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";

const LogoAnimation = ({ onComplete }) => {
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowContent(true);
      if (onComplete) onComplete();
    }, 3000);
    return () => clearTimeout(timer);
  }, [onComplete]);

  return !showContent ? (
    <motion.div
      initial={{ opacity: 0, scale: 0 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 2 }}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <img
        src="/logo.png"
        alt="Logo"
        style={{ width: "150px", height: "150px" }}
      />
    </motion.div>
  ) : null;
};

export default LogoAnimation;
