// React imports
import { useCallback, useState } from "react";

// External libraries
import { Link, useParams } from "react-router-dom";

// Layouts
import MainLayout from "layouts/mainLayout";

// Material Kit 2 PRO React components
import MKButton from "components/MKButton";
import Toolbar from "components/Toolbar";

// Providers
import InvoicesTable from "pages/Invoices/table";
import rentalsProvider from "providers/rentalsProvider";

function RentalInvoicesPage() {
  // Get the rental ID from the URL
  const { id } = useParams();

  // Loading state for the table
  const [loading, setLoading] = useState(true);

  // Query state for searching rentals
  const [query, setQuery] = useState("");

  const fetchData = useCallback(
    async function (page) {
      setLoading(true);
      const {
        data: { data: rentalsData, total },
      } = await rentalsProvider.getRentalInvoices(id, page + 1, query);
      setLoading(false);
      return { invoices: rentalsData, total };
    },
    [id, query]
  );

  return (
    <MainLayout title="Cobros">
      <Toolbar
        setQuery={setQuery}
        ransackQuery="customer_first_name_or_customer_last_name_or_unit_name_or_status_cont"
        newItemButton={
          <MKButton
            color="primary"
            size="small"
            variant="contained"
            component={Link}
            to="/invoices/new"
          >
            + Agregar Cobro
          </MKButton>
        }
      />

      <InvoicesTable fetchData={fetchData} loading={loading} />
    </MainLayout>
  );
}

export default RentalInvoicesPage;
