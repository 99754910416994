import { useState, useMemo, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

// Material UI components
import Grid from "@mui/material/Grid";
import MKButton from "components/MKButton";
import MKBox from "components/MKBox";

// Custom components
import NavTabs from "components/NavTabs";

// Payment type tables
import DepositsTable from "pages/Payments/tabs/depositsTable";
import ProductsTable from "pages/Payments/tabs/productsTable";
import RentalsTable from "pages/Payments/tabs/rentalsTable";

function TabIndex({ defaultTab = 0, query }) {
  const location = useLocation();
  const state = useMemo(() => location.state || {}, [location.state]);
  const [tab, setTab] = useState(() => {
    return localStorage.getItem("activeTab")
      ? Number(localStorage.getItem("activeTab"))
      : state.defaultTab ?? defaultTab;
  });

  const handleTabChange = (newValue) => {
    setTab(newValue);
  };

  useEffect(() => {
    localStorage.setItem("activeTab", tab);
  }, [tab]);

  const tabs = [
    { label: "Rentas", icon: "home" },
    { label: "Depósitos", icon: "attach_money" },
    { label: "Productos", icon: "store" },
  ];

  return (
    <>
      <Grid item xs={12} lg={12}>
        <NavTabs tabs={tabs} active={tab} onChange={handleTabChange} />
      </Grid>
      <MKBox my={2} />
      <MKBox>
        <MKBox>
          <MKButton
            variant="contained"
            size="small"
            color="primary"
            component={Link}
            to={`/payments/new?type=${tab}`}
          >
            + Agregar pago
          </MKButton>
        </MKBox>
      </MKBox>
      <Grid item xs={12} lg={12}>
        {tab === 0 && <RentalsTable query={query} />}
        {tab === 1 && <DepositsTable query={query} />}
        {tab === 2 && <ProductsTable query={query} />}
      </Grid>
    </>
  );
}

export default TabIndex;
