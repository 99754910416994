import axios from "axios";
import { getCurrentUserToken } from "utils";

class UserProvider {
  axiosClient = axios.create({
    baseURL: `${process.env.REACT_APP_BASE_API_URL}/users`,
  });

  getAdmins(page = null, query = null) {
    const token = getCurrentUserToken();
    return this.axiosClient.get("/admins", {
      params: { q: JSON.stringify(query), page: page },
      headers: { "X-USER-TOKEN": token },
    });
  }

  getManagers(page = null, query = null) {
    const token = getCurrentUserToken();
    return this.axiosClient.get("/managers", {
      params: { q: JSON.stringify(query), page: page },
      headers: { "X-USER-TOKEN": token },
    });
  }

  createUser(userParams) {
    const token = getCurrentUserToken();
    return this.axiosClient.post("/", userParams, {
      headers: { "X-USER-TOKEN": token },
    });
  }

  updateUser(id, userParams) {
    const token = getCurrentUserToken();
    return this.axiosClient.put(`/${id}`, userParams, {
      headers: { "X-USER-TOKEN": token },
    });
  }

  deleteUser(id) {
    const token = getCurrentUserToken();
    return this.axiosClient.delete(`/${id}`, {
      headers: { "X-USER-TOKEN": token },
    });
  }
}

const userProviderInstance = new UserProvider();
export default userProviderInstance;
