import React, { useEffect, useState } from "react";
import { Box, Divider, Typography } from "@mui/material";
import { motion } from "framer-motion";

const NodeMenu = ({ selectedFloor, unitNodes }) => {
  const [filteredNodes, setFilteredNodes] = useState([]);

  // Función para manejar el drag de nodos
  const onDragStart = (event, draggedNode) => {
    event.dataTransfer.setData(
      "application/reactflow",
      JSON.stringify(draggedNode)
    );
    event.dataTransfer.effectAllowed = "move";
  };

  useEffect(() => {
    const newFiltered = selectedFloor
      ? unitNodes.filter((node) => node.data.floor === selectedFloor)
      : unitNodes;

    setFilteredNodes(newFiltered);
  }, [selectedFloor, unitNodes]);

  return (
    <Box
      component={motion.div}
      initial={{ x: 100 }}
      animate={{ x: 0 }}
      transition={{ duration: 0.5 }}
      sx={{
        width: "200px",
        backgroundColor: "#f7f7f7",
        borderLeft: "1px solid #ddd",
        padding: "10px",
        height: "70vh",
        overflowY: "auto",
        position: "absolute",
        right: 0,
        top: 0,
        zIndex: 10,
      }}
    >
      <Typography variant="h6" gutterBottom>
        Arrastra un nodo al mapa:
      </Typography>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {/* Nodo de tipo Custom */}
        <div
          onDragStart={(event) => onDragStart(event, { type: "custom" })}
          draggable
          style={{
            padding: "10px",
            margin: "5px",
            backgroundColor: "#1A91DA",
            color: "#fff",
            cursor: "grab",
            textAlign: "center",
            borderRadius: "4px",
            width: "100%",
          }}
        >
          + Oficina
        </div>
        <Divider />

        {/* Renderizar nodos unidades*/}
        {filteredNodes.map((node) => (
          <div
            key={node.id}
            onDragStart={(event) => onDragStart(event, node)}
            draggable
            style={{
              padding: "10px",
              margin: "5px",
              backgroundColor: "#E78D0D",
              color: "#fff",
              cursor: "grab",
              textAlign: "center",
              borderRadius: "4px",
              width: "100%",
            }}
          >
            + {node.data.label} {/* Muestra el nombre de la unidad */}
          </div>
        ))}
      </div>
    </Box>
  );
};

export default NodeMenu;
